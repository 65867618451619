import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import products from '../helper/List'

// import images
import searchBg from '../assets/images/search-img.png'

// import { motion } from 'framer-motion'

const SearchResults = () => {
  const { t } = useTranslation()
  const { term } = useParams()

  const results = products.filter((item) =>
    item.tags.toLocaleLowerCase().includes(term.toLocaleLowerCase())
  )

  return (
    <Wrapper>
      <div className='results-hero'>
        <div className='content-wrapper inner'>
          <h2>{t('search-results-short')}</h2>
        </div>
      </div>
      <div className='content-wrapper'>
        <div className='content-container'>
          {results.length ? (
            <>
              <h2>
                {t('search-results')} "{term}"
              </h2>
              {results.map((product) => (
                <>
                  {!product.justPdf ? (
                    <NavLink
                      to={`/categories/${product.categorie}/${product.id}`}>
                      <div className='result-wrapper'>
                        <div className='result-image'>
                          <img src={product.image} alt={product.id} />
                        </div>
                        <div className='result-wrapper__description'>
                          <h3 className='bold'>{product.id}</h3>
                          <h3>{product.title}</h3>
                          <div className='result-btn'>
                            <NavLink
                              to={`/categories/${product.categorie}/${product.id}`}>
                              <button className='basic-btn'>
                                {t('more-btn')}
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  ) : (
                    <a
                      href={product.downloads}
                      target='_blank'
                      rel='noreferrer'>
                      <div className='result-wrapper'>
                        <div className='result-image'>
                          <img src={product.image} alt={product.id} />
                        </div>
                        <div className='result-wrapper__description'>
                          <h3 className='bold'>{product.id}</h3>
                          <h3>{product.title}</h3>
                          <div className='result-btn'>
                            <a
                              href={product.downloads}
                              target='_blank'
                              rel='noreferrer'>
                              <button className='basic-btn'>
                                {t('more-btn')}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              <h2>Not found results for "{term}"</h2>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 135px;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }

  .results-hero {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 427px;
    background: url(${searchBg}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      height: 56.2vh;
      background: url(${searchBg}) no-repeat center/cover;
    }
  }

  .content-wrapper {
    width: 1168px;
    height: 100%;
    max-width: 90%;
    padding-block: 6rem;
    margin: auto;

    @media screen and (max-width: 1024px) {
      padding-top: 3rem;
      padding-bottom: 0;
      height: auto;
      max-width: 90%;
    }

    h2 {
      font-size: calc(var(--title-font-size) + 0.5rem);
      color: var(--title-text-color);
      text-transform: uppercase;
      margin-bottom: 3rem;
      margin-inline: auto;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.3rem);
      }
    }

    .content-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      h3 {
        font-size: var(--paragraph-font-size);
        color: var(--title-text-color);
        font-weight: 400;

        @media (max-width: 1024px) {
          font-size: calc(var(--title-font-size) - 0.5rem);
          text-align: center;
          text-align: left;
        }
      }

      hr {
        width: 12%;
        height: 3px;
        border: none;
        background: var(--cooper-red);

        @media screen and (max-width: 1024px) {
          /* margin: auto; */
        }
      }

      .result-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: fit-content;
        border: 1px solid #b3b3b3;
        padding: 1rem;
        margin-bottom: 2rem;
        gap: 5rem;

        @media (max-width: 1024px) {
          flex-direction: column;
          margin-bottom: 4rem;
          height: 100%;
          gap: 1rem;
        }

        .result-image {
          width: 25%;

          img {
            width: 100%;
          }

          @media (max-width: 1024px) {
            width: 90%;
          }
        }

        .result-wrapper__description {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-start;

          .bold {
            font-weight: 600;
          }

          .result-btn {
            width: 100%;
          }

          .basic-btn {
            display: inline-block;
            color: var(--title-text-color);
            background: transparent;
            text-transform: uppercase;
            padding: 0.5rem 4rem;
            border: 2px solid var(--title-text-color);
            margin-top: 2rem;
            transition: all 0.3s ease-in-out;
            font-size: 1.2rem;
            font-weight: 600;
            text-align: center;
            cursor: pointer;

            :hover {
              background-color: var(--secondary-bg);
              color: var(--primary-bg);
              border-color: var(--secondary-bg);
            }

            @media screen and (max-width: 1024px) {
              width: 100%;
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    h2 {
      color: #f2f2f2;
      font-size: var(--main-title-font-size);
      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.5rem);
      }
    }

    @media (max-width: 1024px) {
      padding: 0;
    }
  }
`

export default SearchResults
