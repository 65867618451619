import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

// import hero slider images
import slide1 from '../assets/images/slide-1.webp'
import slide2 from '../assets/images/slide-2.webp'
import slide3 from '../assets/images/slide-3.webp'

// import mobile hero slider images
import slide1mob from '../assets/images/slide-1-mobile.webp'
import slide2mob from '../assets/images/slide-2-mobile.webp'
import slide3mob from '../assets/images/slide-3-mobile.webp'

//featured products
import FeaturedProducts from '../components/FeaturedProducts'

// bg and categories images
import categoriesImg from '../assets/images/mainpage-cat-bg.webp'
import miniSplit from '../assets/images/split.png'
import multiImage from '../assets/images/multiImage.png'
import kasetteImg from '../assets/images/kassette.jpeg'
import heatpumps from '../assets/images/heatpumps.webp'
import vrfImage from '../assets/images/vrf-image.jpeg'
import airChannel from '../assets/images/airchannel.png'
import heatpumpWaterImg from '../assets/images/heat-pump-pool.png'
import topProducts from '../assets/images/mountains-bg.png'
import wallKlima from '../assets/images/slide-klima.png'
import empty from '../assets/images/empty.png'

// import icons
import split from '../assets/icons/minisplit.svg'
import multi from '../assets/icons/multi.svg'
import casette from '../assets/icons/casette.svg'
import heat from '../assets/icons/heatpumps.svg'
import vrf from '../assets/icons/vrf.svg'
import dehumi from '../assets/icons/dehumifidiers.svg'

// import products PDF's -> heat-pump, VRF
import heatPump from '../assets/downloads/products/CH HEAT PUMP.pdf'
import vrfProduct from '../assets/downloads/products/CHV5_catalogue_ENG.pdf'

const heroCarouselVariant = {
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { duration: 0.8, delay: 0.2 },
  },
  hidden: { x: 0, y: '-30vh', opacity: 0 },
}

function HeroSwiper({ children, classN }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial='hidden'
      variants={heroCarouselVariant}
      exit={{ x: 0, y: '-30vh', opacity: 0 }}
      className={classN}>
      {children}
    </motion.div>
  )
}

const MainPage = () => {
  const { t } = useTranslation()
  const isMobile = window.innerWidth < 1024

  return (
    <Wrapper>
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay, Pagination]}
        loop={true}
        pagination
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={1000}
        className='mySwiper'>
        <SwiperSlide className='slide-block'>
          {!isMobile ? (
            <HeroSwiper classN={'swiper-init'}>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img
                    loading='lazy'
                    src={wallKlima}
                    alt='Cooper Hunter klima'
                  />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-1-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img src={slide2} alt='cooper hunter' />
            </HeroSwiper>
          ) : (
            <>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img
                    loading='lazy'
                    src={wallKlima}
                    alt='Cooper Hunter klima'
                  />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-1-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img src={slide1mob} loading='lazy' alt='cooper hunter' />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide className='slide-block'>
          {!isMobile ? (
            <HeroSwiper classN={'swiper-init'}>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img loading='lazy' src={empty} alt='Cooper Hunter klima' />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-2-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img loading='lazy' src={slide1} alt='cooper hunter' />
            </HeroSwiper>
          ) : (
            <>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img loading='lazy' src={empty} alt='Cooper Hunter klima' />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-2-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img loading='lazy' src={slide2mob} alt='cooper hunter' />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide className='slide-block'>
          {!isMobile ? (
            <HeroSwiper classN={'swiper-init'}>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img loading='lazy' src={empty} alt='Cooper Hunter klima' />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-3-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img loading='lazy' src={slide3} alt='cooper hunter' />
            </HeroSwiper>
          ) : (
            <>
              <div className='anim-text'>
                <div className='wallklima'>
                  <img loading='lazy' src={empty} alt='Cooper Hunter klima' />
                </div>
                <h2>{t('carousel-h2')}</h2>
                <h3>{t('carousel-3-h3')}</h3>
                <a href='#categories' className='hero-btn'>
                  {t('btn-termekeink')}
                </a>
              </div>
              <img src={slide3mob} loading='lazy' alt='cooper hunter' />
            </>
          )}
        </SwiperSlide>
      </Swiper>
      <div className='wellcome-content-wrapper'>
        <div className='welcome'>
          <div className='left'>
            <h3 className='blue-title'>{t('welcome')}</h3>
            <h3 className='subtitle'>{t('carousel-h2')}</h3>
            <hr />
            <p className='lead-text'>{t('welcome-lead-text')}</p>
          </div>
          <div className='right'>
            <p className='smpl-text'>{t('welcome-text')}</p>
            <a className='basic-btn' href='#categories'>
              {t('catalogue')}
            </a>
          </div>
        </div>
      </div>
      <div id='categories'></div>
      <div className='categories-bg'>
        <div className='categories-bg-wrapper'>
          <h2>{t('categories')}</h2>
          {!isMobile ? (
            <HeroSwiper>
              <ul className='category-icon-list'>
                <NavLink to='/categories/split'>
                  <li>
                    <img loading='lazy' src={split} alt='Mini-split system' />
                    <p>{t('mini-split-system')}</p>
                  </li>
                </NavLink>
                <NavLink to='/categories/multi'>
                  <li>
                    <img loading='lazy' src={multi} alt={t('multi-systems')} />
                    <p>{t('multi-systems')}</p>
                  </li>
                </NavLink>
                <NavLink to='/categories/kassetten'>
                  <li>
                    <img
                      loading='lazy'
                      src={casette}
                      alt={t('casette-split-conditioners')}
                    />
                    <p>{t('casette-split-conditioners')}</p>
                  </li>
                </NavLink>
                <NavLink to='categories/warmepumpen'>
                  <li>
                    <img src={heat} alt={t('heat-pumps')} />
                    <p>{t('heat-pumps')}</p>
                  </li>
                </NavLink>
                <a href={vrfProduct} target='_blank' rel='noreferrer'>
                  <li>
                    <img loading='lazy' src={vrf} alt={t('VRF')} />
                    <p>{t('VRF')}</p>
                  </li>
                </a>
                <NavLink to='/categories/kanalisierte'>
                  <li>
                    <img loading='lazy' src={dehumi} alt={t('heatchannel')} />
                    <p>{t('heatchannel')}</p>
                  </li>
                </NavLink>
              </ul>
            </HeroSwiper>
          ) : (
            <ul className='category-icon-list'>
              <NavLink to='/categories/split'>
                <li>
                  <img loading='lazy' src={split} alt='Mini-split system' />
                  <p>{t('mini-split-system')}</p>
                </li>
              </NavLink>
              <NavLink to='/categories/multi'>
                <li>
                  <img loading='lazy' src={multi} alt={t('multi-systems')} />
                  <p>{t('multi-systems')}</p>
                </li>
              </NavLink>
              <NavLink to='/categories/kassetten'>
                <li>
                  <img
                    loading='lazy'
                    src={casette}
                    alt={t('casette-split-conditioners')}
                  />
                  <p>{t('casette-split-conditioners')}</p>
                </li>
              </NavLink>
              <NavLink
                to='/categories/warmepumpen'
                target='_blank'
                rel='noreferrer'>
                <li>
                  <img loading='lazy' src={heat} alt={t('heat-pumps')} />
                  <p>{t('heat-pumps')}</p>
                </li>
              </NavLink>
              <a href={vrfProduct} target='_blank' rel='noreferrer'>
                <li>
                  <img loading='lazy' src={vrf} alt={t('VRF')} />
                  <p>{t('VRF')}</p>
                </li>
              </a>
              <NavLink to='/categories/kanalisierte'>
                <li>
                  <img loading='lazy' src={dehumi} alt={t('heatchannel')} />
                  <p>{t('heatchannel')}</p>
                </li>
              </NavLink>
            </ul>
          )}
        </div>
      </div>
      <div className='content-wrapper'>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>
                  {t('mini-split-system')}
                </h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>
                  {t('mini-split-system')}
                </h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('split-desc-1')}</p>
            <p className='categories-desc'>{t('split-desc-2')}</p>
            <NavLink to='/categories/split' className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img loading='lazy' src={miniSplit} alt='Mini-split systems' />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>{t('multi-systems')}</h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>{t('multi-systems')}</h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('multi-desc-1')}</p>
            <p className='categories-desc'>{t('multi-desc-2')}</p>
            <NavLink to='/categories/multi' className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img loading='lazy' src={multiImage} alt='Multi Air Conditioners' />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>
                  {t('casette-split-conditioners')}
                </h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>
                  {t('casette-split-conditioners')}
                </h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('kasetten-split-desc-1')}</p>
            <p className='categories-desc'>{t('kasetten-split-desc-2')}</p>
            <NavLink to='/categories/kassetten' className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img
              loading='lazy'
              src={kasetteImg}
              alt='Commercial Air Conditioners'
            />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>{t('heat-pumps')}</h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>{t('heat-pumps')}</h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('hoszivattyu-desc-1')}</p>
            <p className='categories-desc'>{t('hoszivattyu-desc-2')}</p>
            <NavLink to='/categories/warmepumpen' className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img loading='lazy' src={heatpumps} alt='Heat Pumps' />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>{t('VRF')}</h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>{t('VRF')}</h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('VRF-desc-1')}</p>
            <p className='categories-desc'>{t('VRF-desc-2')}</p>
            <a
              href={vrfProduct}
              target='_blank'
              rel='noreferrer'
              className='basic-btn'>
              {t('more-btn')}
            </a>
          </div>
          <div className='right'>
            <img loading='lazy' src={vrfImage} alt={t('heatchannel')} />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>{t('heatchannel')}</h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>{t('heatchannel')}</h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('legcsatorna-desc-1')}</p>
            <p className='categories-desc'>{t('legcsatorna-desc-2')}</p>
            <NavLink to='/categories/kanalisierte' className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img loading='lazy' src={airChannel} alt={t('heatchannel')} />
          </div>
        </div>
        <div className='klima-categories-block'>
          <div className='left'>
            {!isMobile ? (
              <HeroSwiper>
                <h3 className='categories-subtitle'>{t('heat-pumps-water')}</h3>
                <hr />
              </HeroSwiper>
            ) : (
              <>
                <h3 className='categories-subtitle'>{t('heat-pumps-water')}</h3>
                <hr />
              </>
            )}
            <p className='categories-desc'>{t('heatpumps-water-desc-1')}</p>
            <p className='categories-desc'>{t('heatpumps-water-desc-2')}</p>
            <NavLink
              to='/categories/schwimmbad-pool-wasser-warmepumpen'
              className='basic-btn'>
              {t('more-btn')}
            </NavLink>
          </div>
          <div className='right'>
            <img loading='lazy' src={heatpumpWaterImg} alt='Heat Pumps Water' />
          </div>
        </div>
      </div>
      <div className='top-products-bg'>
        <div className='top-products-content-container'>
          <h2>{t('top-products-h2')}</h2>
          <p>{t('top-products-desc')}</p>
        </div>
      </div>
      <div className='content-wrapper bottom-slider'>
        <FeaturedProducts />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .mySwiper {
    width: 100%;
    height: 100vh;

    @media (max-width: 1024px) {
      height: 100%;
    }
  }

  .bottom-slider .swiper-container {
    min-height: 33vh;
  }

  .swiper-pagination-bullet {
    background: var(--primary-bg);
    opacity: 1;
    width: 2%;
    height: 3px;
    border-radius: 0;

    @media (max-width: 1024px) {
      width: 8%;
      height: 3px;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--secondary-bg);
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .slide-block {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .swiper-init {
    position: absolute;
    top: 135px;
    left: 0;
    width: 100%;
    height: calc(100vh - 135px);
  }

  .anim-text {
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 100;

    @media screen and (max-width: 1024px) {
      top: 0;
    }

    .wallklima {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      height: auto;
      text-align: center;
      align-items: center;
      margin-bottom: 2rem;

      img {
        width: 300px;
        height: 100px;

        @media screen and (max-width: 1024px) {
          width: auto;
          height: 80px;
        }
      }
    }

    h2 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.4rem);
      }
    }

    h3 {
      font-size: calc(var(--title-font-size) + 0.8rem);
      color: var(--primary-bg);
      font-weight: 400;
      margin-bottom: 1rem;

      @media (max-width: 1024px) {
        font-size: calc(var(--title-font-size) - 0.9rem);
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.5rem;
      }
    }
  }

  .hero-btn {
    display: inline-block;
    color: #fff;
    background: transparent;
    padding: 1rem 2rem;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;

    :hover {
      cursor: pointer;
      background-color: var(--primary-bg);
      color: var(--title-text-color);
    }

    @media screen and (max-width: 1024px) {
      padding: 0.5rem 1rem;
      margin-top: 1rem;
    }
  }

  .basic-btn {
    display: inline-block;
    color: var(--title-text-color);
    background: transparent;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border: 2px solid var(--title-text-color);
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-bg);
      color: var(--primary-bg);
      border-color: var(--secondary-bg);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media (max-width: 1024px) {
      height: 63vh;
      object-fit: cover;
      /* filter: brightness(0.7); */
    }
  }

  .wellcome-content-wrapper {
    width: 1168px;
    max-width: 90%;
    padding-top: 9rem;
    margin: auto;

    @media (max-width: 1024px) {
      padding-top: 4rem;
    }
  }

  #categories {
    padding-top: 9rem;

    @media screen and (max-width: 1024px) {
      padding-top: 4rem;
    }
  }

  .content-wrapper {
    width: 1168px;
    max-width: 90%;
    padding-block: 8rem;
    margin: auto;

    @media (max-width: 1024px) {
      padding: 4rem 0;
    }
  }

  .klima-categories-block:not(:last-child) {
    margin-bottom: 7rem;

    @media screen and (max-width: 1024px) {
      margin-bottom: 3rem;
    }
  }

  .klima-categories-block:nth-child(even) .left {
    order: 1;
  }

  .welcome,
  .klima-categories-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .left {
      width: 50%;
      /* position: relative; */

      @media (max-width: 1024px) {
        width: 100%;
        position: relative;
        margin-bottom: 2rem;
      }

      .blue-title {
        color: var(--secondary-bg);
        text-transform: uppercase;
        font-size: 20px;

        @media screen and (max-width: 1024px) {
          text-align: left;
        }
      }

      .subtitle {
        font-size: calc(var(--title-font-size) + 0.25rem);
        text-transform: uppercase;
        font-family: var(--title-font);

        @media screen and (max-width: 1024px) {
          font-size: var(--title-font-size);
        }
      }

      .categories-subtitle {
        position: absolute;
        top: -40px;

        @media screen and (max-width: 1024px) {
          font-size: 20px;
          margin: auto;
          top: -30px;
        }
      }

      .categories-desc {
        font-size: 1.25rem;
        color: rgba(153, 153, 153, 1);
        padding-top: 2.5rem;
        padding-bottom: 1rem;

        @media screen and (max-width: 1024px) {
          font-size: var(--paragraph-font-size);
          padding-top: 2rem;
        }
      }

      .lead-text {
        color: var(--light-grey);
        font-weight: 500;
        font-style: italic;
        padding-top: 3rem;

        @media screen and (max-width: 1024px) {
          padding-top: 1rem;
        }
      }
    }

    hr {
      width: 12%;
      height: 3px;
      border: none;
      background: var(--cooper-red);

      @media screen and (max-width: 1024px) {
        /* margin: auto; */
      }
    }

    .right {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 100%;
        height: auto;
      }

      @media (max-width: 1024px) {
        width: 100%;

        margin-bottom: 1rem;
      }

      .smpl-text {
        font-size: var(--paragraph-font-size);
        margin-bottom: 2rem;
      }
    }
  }

  .categories-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 50px;
    width: 100%;
    height: calc(100vh - 135px);
    background: url(${categoriesImg}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      height: 100%;
      padding-bottom: 3rem;
    }

    .categories-bg-wrapper {
      width: 100%;
      position: relative;

      @media screen and (max-width: 1024px) {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
      }

      h2 {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -300%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 1168px;
        max-width: 80%;
        margin: auto;
        height: fit-content;
        font-size: var(--main-title-font-size);
        color: var(--primary-bg);
        text-transform: uppercase;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      .category-icon-list {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 1168px;
        max-width: 90%;
        margin: auto;
        gap: 1.5rem;
        padding-top: 0;

        @media screen and (max-width: 1024px) {
          width: 100%;
          flex-direction: column;
          gap: 2rem;
        }

        li {
          background-color: #f2f2f2;
          display: flex;
          width: 165px;
          height: 165px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 0.3rem;
          border-radius: 10px;
          transition: all 0.2s ease-in-out;

          :hover {
            box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.65);
            transform: translate(-2px, -2px);
          }

          :active {
            box-shadow: none;
            transform: translate(0, 0);
          }

          p {
            font-size: calc(var(--paragraph-font-size) - 0.3rem);
          }

          @media screen and (max-width: 1024px) {
            width: 90vw;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            height: auto;

            p {
              font-size: calc(var(--paragraph-font-size) - 0.15rem);
            }
          }

          img {
            width: 75px;
            height: 75px;
            padding: 0.5rem;
          }
        }

        @media screen and (max-width: 1024px) {
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-block: 4rem;
        }
      }
    }
  }

  .top-products-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    background: url(${topProducts}) no-repeat center/cover;

    @media (max-width: 1024px) {
      min-height: 70vh;
    }

    .top-products-content-container {
      width: 1168px;
      max-width: 80%;
      margin: auto;

      @media (max-width: 1024px) {
        text-align: left;
        max-width: 90%;
      }

      h2 {
        font-size: var(--main-title-font-size);
        color: var(--primary-bg);
        margin-bottom: 1rem;

        @media (max-width: 1024px) {
          font-size: calc(var(--main-title-font-size) - 0.3rem);
          margin-bottom: 2rem;
        }
      }

      p {
        color: var(--primary-bg);
        text-align: left;
        font-size: var(--paragraph-font-size);
      }
    }
  }

  .swiper-container {
    display: flex;
    flex-direction: row;
    width: 1168px;
    height: 20vh;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 30vh;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .categories-subtitle {
    font-family: var(--basic-font);
    font-size: calc(var(--title-font-size) - 0.12rem);
    text-transform: none;

    @media screen and (max-width: 1024px) {
      text-align: left;
    }
  }

  .hr-2 {
    width: 5%;
    height: 3px;
    border: none;
    background: var(--cooper-red);
    margin-bottom: 2rem;

    @media screen and (max-width: 1024px) {
      width: 12%;
    }
  }

  @media screen and (max-width: 1024px) {
    .klima-categories-block .left {
      order: 1;
    }

    .klima-categories-block img {
      margin-bottom: 1.5rem;
    }
  }
`

export default MainPage
