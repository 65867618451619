// PDF's
import CH_09_paraphet from '../assets/downloads/instructions/CH-S09FVX_paraphet_split.pdf'
import CH_12_paraphet from '../assets/downloads/instructions/CH-S12FVX_paraphet_split.pdf'
import CH_18_paraphet from '../assets/downloads/instructions/CH-S18FVX_paraphet_split.pdf'
import CH9_alpha from '../assets/downloads/instructions/CH_09_alpha.pdf'
import CH9_veritas from '../assets/downloads/instructions/CH_09_veritas.pdf'
import CH12_alpha from '../assets/downloads/instructions/CH_12_alpha.pdf'
import CH12_veritas from '../assets/downloads/instructions/CH_12_veritas.pdf'
import CH18_alpha from '../assets/downloads/instructions/CH_18_alpha.pdf'
import CH18_veritas from '../assets/downloads/instructions/CH_18_veritas.pdf'
import CH24_alpha from '../assets/downloads/instructions/CH_24_alpha.pdf'
import CH24_veritas from '../assets/downloads/instructions/CH_24_veritas.pdf'

// PDF PARAPHET
import paraphetPDF from '../assets/downloads/products/Consol adatlap.pdf'

// PDF ALPHA
import alphaPDF from '../assets/downloads/products/alpha.pdf'

// PDF ARCTIC
import arcticPDF from '../assets/downloads/products/arctic.pdf'

// PDF VERITAS
import veritasPDF from '../assets/downloads/products/veritas.pdf'

// PDF SUPREME Kontinental
import supremeKontPDF from '../assets/downloads/products/supreme_kontinental.pdf'

// PDF NORDIC
import nordicPDF from '../assets/downloads/products/nordic_evo2.pdf'

// PDF VITAL
import vitalPDF from '../assets/downloads/products/vital.pdf'

// PDF SUPREME
import supremePDF from '../assets/downloads/products/online katalógus_Rész8.pdf'

// PDF DAYTONA
import daytonaPDF from '../assets/downloads/products/DAYTONA.pdf'

// PDF's MULTI OUTDOOR
import multi_outdoor from '../assets/downloads/products/NORDIC MULTI LIGHT.pdf'

// PDF ALL SYSTEM IN ONE
import catalogue from '../assets/downloads/catalouges/CH klima katalógus osztrák.pdf'

// PDF KANALISIERTE
import kanalPDF from '../assets/downloads/products/Légcsatornás adatlap.pdf'

// PDF KASSETTEN
import kassettenPDF from '../assets/downloads/products/online_katalógus_Rész50.pdf'

// PDF WARMEPUMPEN SCHWIMMBAD
import poolPDF from '../assets/products/pool/Poolwaermepumpe preisliste 2022 ÁRNÉLKÜL.pdf'

// PDF WARMEPUMPEN NEW
import warmeEcoPDF from '../assets/products/warmepumpe/ecopower/Heat_pump_ECOPOWER_(EN)_280322.pdf'
import warmeEviPDF from '../assets/products/warmepumpe/evipower/Heat_pump_EVIPOWER_Inverter_(EN) (2).pdf'
import warmeBasicPDF from '../assets/products/warmepumpe/basic/CH HEAT PUMP.pdf'

// IMAGES SUPREME INDOOR
import supremeMainGoldImg from '../assets/products/ch-s18ftxam2s-gd/ch-s18ftxam2s-gd.png'
import supremeMainSilverImg from '../assets/products/ch-s18ftxam2s-sc/ch-s18ftxam2s-sc.png'
import supremeMainBlackImg from '../assets/products/ch-s18ftxam2s-bl/ch-s18ftxam2s-bl.png'
import supremeGoldImg1 from '../assets/products/ch-s18ftxam2s-gd/ch-s18ftxam2s-gd_1.jpg'
import supremeSilverImg1 from '../assets/products/ch-s18ftxam2s-sc/ch-s18ftxam2s-sc_1.jpg'
import supremeBlackImg1 from '../assets/products/ch-s18ftxam2s-bl/ch-s18ftxam2s-bl_1.jpg'
import supremeBasicImg1 from '../assets/products/ch-s18ftxam2s-gd/ch-s18ftxam2s-gd_2.jpg'
import supremeBasicImg2 from '../assets/products/ch-s18ftxam2s-gd/ch-s18ftxam2s-gd_3.jpg'
import supremeBasicImg3 from '../assets/products/ch-s18ftxam2s-gd/ch-s18ftxam2s-gd_4.jpg'

// IMAGES KASETTEN
import kassetten1Img from '../assets/products/kassetten/ch-ic-035-2.jpeg'
import kassetten2Img from '../assets/products/kassetten/ch-ic-035.jpeg'
import kassetten3Img from '../assets/products/kassetten/IC-100RKE.jpeg'

import CH09_paraphet from '../assets/products/ch-s09fvx/CH-S09FVX-paraphet.jpg'

import CH09_alpha_1 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng.png'
import CH09_alpha_2 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_1.jpg'
import CH09_alpha_3 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_2.jpg'
import CH09_alpha_4 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_3.jpg'
import CH09_alpha_5 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_4.jpg'

import CH09_arctic_1 from '../assets/products/ch-s09ftxla-ng/ch-s09ftxla-ng.png'
import CH09_arctic_2 from '../assets/products/ch-s09ftxla-ng/ch-s09ftxla-ng_1.jpg'
import CH09_arctic_3 from '../assets/products/ch-s09ftxla-ng/ch-s09ftxla-ng_2.jpg'
import CH09_arctic_4 from '../assets/products/ch-s09ftxla-ng/ch-s09ftxla-ng_3.jpg'

import CH09_nordic_1 from '../assets/products/ch-s09ftxn-ng/ch-s09ftxn-ng.jpg'
import CH09_nordic_2 from '../assets/products/ch-s09ftxn-ng/ch-s09ftxn-ng_1.jpg'
import CH09_nordic_3 from '../assets/products/ch-s09ftxn-ng/ch-s09ftxn-ng_2.jpg'
import CH09_nordic_4 from '../assets/products/ch-s09ftxn-ng/ch-s09ftxn-ng_3.jpg'

import CH09_veritas_1 from '../assets/products/ch-s09ftxq-ng/ch-s09ftxq-ng.png'
import CH09_veritas_2 from '../assets/products/ch-s09ftxq-ng/ch-s09ftxq-ng_1.jpg'
import CH09_veritas_3 from '../assets/products/ch-s09ftxq-ng/ch-s09ftxq-ng_2.jpg'
import CH09_veritas_4 from '../assets/products/ch-s09ftxq-ng/ch-s09ftxq-ng_3.jpg'
import CH09_veritas_5 from '../assets/products/ch-s09ftxq-ng/ch-s09ftxq-ng_4.jpg'

import CH12_alpha_1 from '../assets/products/ch-s12ftxe-ng/ch-s12ftxe-ng.png'
import CH12_alpha_2 from '../assets/products/ch-s12ftxe-ng/ch-s12ftxe-ng_1.jpg'
import CH12_alpha_3 from '../assets/products/ch-s12ftxe-ng/ch-s12ftxe-ng_2.jpg'
import CH12_alpha_4 from '../assets/products/ch-s12ftxe-ng/ch-s12ftxe-ng_3.jpg'
import CH12_alpha_5 from '../assets/products/ch-s12ftxe-ng/ch-s12ftxe-ng_4.jpg'

import CH12_arctic_1 from '../assets/products/ch-s12ftxla-ng/ch-s12ftxla-ng.png'
import CH12_arctic_2 from '../assets/products/ch-s12ftxla-ng/ch-s12ftxla-ng_1.jpg'
import CH12_arctic_3 from '../assets/products/ch-s12ftxla-ng/ch-s12ftxla-ng_2.jpg'
import CH12_arctic_4 from '../assets/products/ch-s12ftxla-ng/ch-s12ftxla-ng_3.jpg'

import CH12_nordic_1 from '../assets/products/ch-s12ftxn-ng/ch-s12ftxn-ng.jpg'
import CH12_nordic_2 from '../assets/products/ch-s12ftxn-ng/ch-s12ftxn-ng_1.jpg'
import CH12_nordic_3 from '../assets/products/ch-s12ftxn-ng/ch-s12ftxn-ng_2.jpg'
import CH12_nordic_4 from '../assets/products/ch-s12ftxn-ng/ch-s12ftxn-ng_3.jpg'

import CH12_veritas_1 from '../assets/products/ch-s12ftxq-ng/ch-s12ftxq-ng.png'
import CH12_veritas_2 from '../assets/products/ch-s12ftxq-ng/ch-s12ftxq-ng_1.jpg'
import CH12_veritas_3 from '../assets/products/ch-s12ftxq-ng/ch-s12ftxq-ng_2.jpg'
import CH12_veritas_4 from '../assets/products/ch-s12ftxq-ng/ch-s12ftxq-ng_3.jpg'
import CH12_veritas_5 from '../assets/products/ch-s12ftxq-ng/ch-s12ftxq-ng_4.jpg'

import CH18_alpha_1 from '../assets/products/ch-s18ftxe-ng/ch-s18ftxe-ng.png'
import CH18_alpha_2 from '../assets/products/ch-s18ftxe-ng/ch-s18ftxe-ng_1.jpg'
import CH18_alpha_3 from '../assets/products/ch-s18ftxe-ng/ch-s18ftxe-ng_2.jpg'
import CH18_alpha_4 from '../assets/products/ch-s18ftxe-ng/ch-s18ftxe-ng_3.jpg'
import CH18_alpha_5 from '../assets/products/ch-s18ftxe-ng/ch-s18ftxe-ng_4.jpg'

import CH18_arctic_1 from '../assets/products/ch-s18ftxla-ng/ch-s18ftxla-ng.png'
import CH18_arctic_2 from '../assets/products/ch-s18ftxla-ng/ch-s18ftxla-ng_1.jpg'
import CH18_arctic_3 from '../assets/products/ch-s18ftxla-ng/ch-s18ftxla-ng_2.jpg'
import CH18_arctic_4 from '../assets/products/ch-s18ftxla-ng/ch-s18ftxla-ng_3.jpg'

import CH18_nordic_1 from '../assets/products/ch-s18ftxn-ng/ch-s18ftxn-ng.jpg'
import CH18_nordic_2 from '../assets/products/ch-s18ftxn-ng/ch-s18ftxn-ng_1.jpg'
import CH18_nordic_3 from '../assets/products/ch-s18ftxn-ng/ch-s18ftxn-ng_2.jpg'
import CH18_nordic_4 from '../assets/products/ch-s18ftxn-ng/ch-s18ftxn-ng_3.jpg'
import CH18_nordic_5 from '../assets/products/ch-s18ftxn-ng/ch-s18ftxn-ng_4.jpg'

import CH18_veritas_1 from '../assets/products/ch-s18ftxq-ng/ch-s18ftxq-ng.png'
import CH18_veritas_2 from '../assets/products/ch-s18ftxq-ng/ch-s18ftxq-ng_1.jpg'
import CH18_veritas_3 from '../assets/products/ch-s18ftxq-ng/ch-s18ftxq-ng_2.jpg'
import CH18_veritas_4 from '../assets/products/ch-s18ftxq-ng/ch-s18ftxq-ng_3.jpg'
import CH18_veritas_5 from '../assets/products/ch-s18ftxq-ng/ch-s18ftxq-ng_4.jpg'

import CH24_alpha_1 from '../assets/products/ch-s24ftxe-alpha-67-kw/ch-s24ftxe-alpha-67-kw.png'

import CH24_arctic_1 from '../assets/products/ch-s24ftxla-ng/ch-s24ftxla-ng.png'
import CH24_arctic_2 from '../assets/products/ch-s24ftxla-ng/ch-s24ftxla-ng_1.jpg'
import CH24_arctic_3 from '../assets/products/ch-s24ftxla-ng/ch-s24ftxla-ng_2.jpg'
import CH24_arctic_4 from '../assets/products/ch-s24ftxla-ng/ch-s24ftxla-ng_3.jpg'

import CH24_veritas_1 from '../assets/products/ch-s24ftxq-ng/ch-s24ftxq-ng.png'
import CH24_veritas_2 from '../assets/products/ch-s24ftxq-ng/ch-s24ftxq-ng_1.jpg'
import CH24_veritas_3 from '../assets/products/ch-s24ftxq-ng/ch-s24ftxq-ng_2.jpg'
import CH24_veritas_4 from '../assets/products/ch-s24ftxq-ng/ch-s24ftxq-ng_3.jpg'
import CH24_veritas_5 from '../assets/products/ch-s24ftxq-ng/ch-s24ftxq-ng_4.jpg'

// Multi Klima indoor images
import CHMLS09 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_4.jpg'
import CHMLS09_1 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng.png'
import CHMLS09_3 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_3.jpg'
import CHMLS09_2 from '../assets/products/ch-s09ftxe-ng/ch-s09ftxe-ng_2.jpg'

// Multi Klima outdoor images
import CHMLU14 from '../assets/products/chml-u14rk2-41-kw-multi-kulteri-egyseg-2-belteri-egyseghez/chml-u14rk2-41-kw-multi-kulteri-egyseg-2-belteri-egyseghez.jpg'
import CHMLU18 from '../assets/products/chml-u18rk2-52-kw-multi-kulteri-egyseg-2-belteri-egyseghez/chml-u18rk2-52-kw-multi-kulteri-egyseg-2-belteri-egyseghez.jpg'
import CHMLU21 from '../assets/products/chml-u21rk3-61-kw-multi-kulteri-egyseg-3-belteri-egyseghez/chml-u21rk3-61-kw-multi-kulteri-egyseg-3-belteri-egyseghez.jpg'
import CHMLU24 from '../assets/products/chml-u24rk3-71-kw-multi-kulteri-egyseg-3-belteri-egyseghez/chml-u24rk3-71-kw-multi-kulteri-egyseg-3-belteri-egyseghez.jpg'
import CHMLU28 from '../assets/products/chml-u28rk4-8-kw-multi-kulteri-egyseg-4-belteri-egyseghez/chml-u28rk4-8-kw-multi-kulteri-egyseg-4-belteri-egyseghez.jpg'
import CHMLU36 from '../assets/products/chml-u36rk4-105-kw-multi-kulteri-egyseg-4-belteri-egyseghez/chml-u36rk4-105-kw-multi-kulteri-egyseg-4-belteri-egyseghez.jpg'
import CHMLU42 from '../assets/products/chml-u42rk5-12-kw-multi-kulteri-egyseg-5-belteri-egyseghez/chml-u42rk5-12-kw-multi-kulteri-egyseg-5-belteri-egyseghez.jpg'

// kanalisierte indoor image
import kanalIndoor from '../assets/products/ch-id05/CH-ID050RKE.jpeg'
// kanalisierte outdoor  image
import kanalOutdoor from '../assets/products/ch-ids/ch-ids071.jpeg'
import remoteController from '../assets/products/ch-ids/remote-controller.jpeg'

// WARMEPUMPEN SCHWIMMBAD IMAGE
import pool1 from '../assets/products/pool/heat-pump-pool.png'
import pool2 from '../assets/products/pool/heat-pump-outdoor1.png'
import pool3 from '../assets/products/pool/heat-pump-outdoor2.png'

// WARMEPUMPEN IMAGE
import ecoMainImg from '../assets/products/warmepumpe/ecopower/12.07.23.png'
import ecoImg1 from '../assets/products/warmepumpe/ecopower/11.42.19.png'
import ecoImg2 from '../assets/products/warmepumpe/ecopower/11.41.58.png'
import ecoImg3 from '../assets/products/warmepumpe/ecopower/11.41.31.png'
import eviMainImg from '../assets/products/warmepumpe/evipower/12.07.00.png'
import eviImg1 from '../assets/products/warmepumpe/evipower/11.38.07.png'
import eviImg2 from '../assets/products/warmepumpe/evipower/11.37.43.png'
import eviImg3 from '../assets/products/warmepumpe/evipower/11.37.29.png'
import basicMainImg from '../assets/images/heatpumps.webp'

const products = [
  // SPLIT
  {
    id: 'CH-S09FVX',
    categorie: 'split',
    subcategorie: 'paraphet',
    title: 'Mini-split system inverter consol series CH-S09FVX - Paraphet',
    tags: 'Mini-split system inverter consol series CH-S09FVX - Paraphet',
    image: [CH09_paraphet],
    gallery: [CH09_paraphet],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '2.60 (0.45-3.20)',
    heating: '3.30 (0.45-3.75)',
    downloads: paraphetPDF,
  },
  {
    id: 'CH-S12FVX',
    categorie: 'split',
    subcategorie: 'paraphet',
    title: 'Mini-split system inverter consol series CH-S12FVX - Paraphet',
    tags: 'Mini-split system inverter consol series CH-S12FVX - Paraphet',
    image: [CH09_paraphet],
    gallery: [CH09_paraphet],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '3.52 (0.60-3.95) kW',
    heating: '4.00 (0.60-4.70) kW',
    downloads: paraphetPDF,
  },
  {
    id: 'CH-S18FVX',
    categorie: 'split',
    subcategorie: 'paraphet',
    title: 'Mini-split system inverter consol series CH-S18FVX - Paraphet',
    tags: 'Mini-split system inverter consol series CH-S18FVX - Paraphet',
    image: [CH09_paraphet],
    gallery: [CH09_paraphet],
    description: [
      'Slim size (depth 215 mm)',
      'I Feel function. Temperature sensor in the remote control',
      'Cold air supply in upward direction from the indoor unit',
      'Hot air supply from the bottom side of indoor unit',
      '+8°C button',
      'F-Gas R410A',
      'High energy efficiency class A: EER 3,42; C.O.P. 3,62',
      'New generation DC-inverter rotor compressor',
      'Energy savings up to 59%',
      'Save start at electricity power range from 95 V to 260 V',
      'Stable Frequency Control - smooth and stable operation at compressor frequency ~15 Hz',
      'Noise Analysis Technology - noiseless operation of indoor and outdoor',
      'Energy consumption 1 W in a standby mode',
      'Outdoor temperature range -15°C to +24°C for heating,  -15°C to +48°C for cooling',
      'Precise temperature control, < 0,5°C',
      'Indication of actual indoor and outdoor temperature, indication of indoor temperature',
      'Compressor soft start; electricity current <50A',
      'Energy-saving mode',
      'Automatic air circulation in SWING mode',
      'Turbo Mode button for the fast cooling and heating of the room',
      'Automation operation mode selection: cooling, heating, dehumidifying, ventilation',
      'Delay of operation start to avoid cold air flow',
      'Wide louvers for effective ventilation of the whole room',
      'Self cleaning of indoor unit. Ventilator removes water from heat exchanger after stop of cooling operation. This function prevents growth of bacteria and mold inside indoor units',
      'Bright and clear LED display',
      'Switchable backlight of the indoor unit display',
      'Humidity reduction without temperature decreasing',
      'Comfort mode SLEEP. Noiseless operation during 8 hours',
      'Removable washable prefilter and front panel',
      'On-off timer for 24 hours with 1 minute step',
      'Clocks on remote control',
      'Block function of the remote control',
      'Autorestart with memory of settings',
      'New generation auto defrosting systems Intelligent Preheating. Contrary to traditional defrosting activated regularly after fixed period of time Intelligent Preheating performs defrosting only when it really required. Thus it reduces energy consumption by avoiding unnecessary defrosting cycles.',
      'Anticorrosion finishing of outdoor body',
      'Heat exchanger finishing GREEN-FIN',
      'Self-diagnostic of main functions and modes',
    ],
    cooling: '5.27 (0.90-5.60) kW',
    heating: '5.50 (0.90-6.60) kW',
    downloads: paraphetPDF,
  },
  {
    id: 'CH-S09FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S09FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S09FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH09_alpha_1],
    gallery: [
      CH09_alpha_1,
      CH09_alpha_2,
      CH09_alpha_3,
      CH09_alpha_4,
      CH09_alpha_5,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    cooling: '',
    heating: '',
    downloads: alphaPDF,
  },
  {
    id: 'CH-S12FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S12FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S12FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH12_alpha_1],
    gallery: [
      CH12_alpha_1,
      CH12_alpha_2,
      CH12_alpha_3,
      CH12_alpha_4,
      CH12_alpha_5,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  {
    id: 'CH-S18FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S18FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S18FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH18_alpha_1],
    gallery: [
      CH18_alpha_1,
      CH18_alpha_2,
      CH18_alpha_3,
      CH18_alpha_4,
      CH18_alpha_5,
    ],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  {
    id: 'CH-S24FTXE-NG',
    categorie: 'split',
    subcategorie: 'Alpha',
    title:
      'Cooper&Hunter Alpha CH-S24FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Alpha CH-S24FTXE-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: CH24_veritas_1,
    gallery: [CH24_alpha_1],
    introduction:
      'Alpha - die neueste Version der bei Kunden beliebten Klimaanlage, jetzt mit dem ökologischen Kältemittel R32. Das Gerät ist mit der Filtertechnologie CH 7-SKY und einem Plasma-Ionisator ausgestattet, und die Steuerung wird durch das eingebaute WiFi-Modul erleichtert. Ein zusätzlicher Vorteil ist die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Fortschrittliche Software zur Optimierung des Betriebs der Klimaanlage durch einen weiten Bereich der Rotationsfrequenz des Kompressors',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: alphaPDF,
  },
  {
    id: 'CH-S09FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S09FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S09FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH09_arctic_1],
    gallery: [CH09_arctic_1, CH09_arctic_2, CH09_arctic_3, CH09_arctic_4],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S12FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S12FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S12FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH12_arctic_1],
    gallery: [CH12_arctic_1, CH12_arctic_2, CH12_arctic_3, CH12_arctic_4],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S18FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S18FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S18FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH18_arctic_1],
    gallery: [CH18_arctic_1, CH18_arctic_2, CH18_arctic_3, CH18_arctic_4],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },
  {
    id: 'CH-S24FTXLA-NG',
    categorie: 'split',
    subcategorie: 'Arctic',
    title:
      'Cooper&Hunter Arctic CH-S24FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Arctic CH-S24FTXLA - NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH18_arctic_1],
    gallery: [CH18_arctic_1, CH18_arctic_2, CH18_arctic_3, CH18_arctic_4],
    introduction:
      'Arctic Inverter mit Verwendung des ökologischen Kältemittels R32 wurde entwickelt, um im Heizmodus bei niedrigen Außentemperaturen bis zu -25 ° C zu arbeiten. Das Gerät ist mit einer Auffangwannenheizung, einer Kurbelwannenheizung und einem Luftionisator ausgestattet. Die Steuerung wird durch das eingebaute WiFi-Modul erleichtert.',
    description: [
      'Effektiver Betriebstemperaturbereich von -25 °C bis +48 °C',
      'G-Matrik-Technologie - reibungsloser und stabiler Kompressorbetrieb bei sehr niedrigen Frequenzen',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleisten kann',
      'Trockenkontakt - Die Grundfunktion ist die Fähigkeit, die Klimaanlage mit einem externen Signal ein- und auszuschalten. Es bietet auch die Möglichkeit, das Betriebs- und Störungssignal des Geräts auszugeben',
      'Plasma-Ionisator',
      'Horizontale und vertikale Bewegung der Lamellen für eine bessere Luftverteilung im Raum',
      'Neuer Fahrer mit einer Klappe',
      'Eingebauter Temperatursensor in der Fernbedienung',
    ],
    downloads: arcticPDF,
  },

  {
    id: 'CH-S09FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S09FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S09FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH09_veritas_1],
    gallery: [
      CH09_veritas_1,
      CH09_veritas_2,
      CH09_veritas_3,
      CH09_veritas_4,
      CH09_veritas_5,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S12FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S12FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S12FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH12_veritas_1],
    gallery: [
      CH12_veritas_1,
      CH12_veritas_2,
      CH12_veritas_3,
      CH12_veritas_4,
      CH12_veritas_5,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S18FTXQ-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S18FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S18FTXQ-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH18_veritas_1],
    gallery: [
      CH18_veritas_1,
      CH18_veritas_2,
      CH18_veritas_3,
      CH18_veritas_4,
      CH18_veritas_5,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },
  {
    id: 'CH-S24FTXL2Q-NG',
    categorie: 'split',
    subcategorie: 'Veritas',
    title:
      'Cooper&Hunter Veritas CH-S24FTXL2Q-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Veritas CH-S24FTXL2Q-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    image: [CH24_veritas_1],
    gallery: [
      CH24_veritas_1,
      CH24_veritas_2,
      CH24_veritas_3,
      CH24_veritas_4,
      CH24_veritas_5,
    ],
    introduction:
      'Veritas - die neueste Version der Wandklimaanlage, diesmal mit dem Ökofaktor R32. Das Gerät ist mit CH 7-SKY-Filtertechnologie, eingebautem WiFi-Modul und Plasma-Ionisator ausgestattet. Zudem hat die Klimaanlage sowohl im Kühl- als auch im Heizbetrieb die Energieklasse A++.',
    description: [
      'Plasma-Ionisator',
      'ICH FÜHLE Funktion',
      '+8 Grad funktionieren, um eine positive Temperatur im Raum aufrechtzuerhalten',
      'Eingebautes WiFi-Modul als Standard',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung. Es ist ein Filter, der aus folgenden Filtern besteht: antibakterieller Filter, Silberionenfilter, Catechinfilter, Chitinfilter, Vitamin-C-Filter, antibakterieller Filter und photokatalytischer Filter',
      'Eine neue Fernbedienung mit modernem Design und Tastenbeleuchtung bei Nacht',
    ],
    downloads: veritasPDF,
  },

  // SUPREME KONTINENTAL
  {
    id: 'CH-S09FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S09FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S09FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeMainSilverImg,
    gallery: [
      supremeMainSilverImg,
      supremeMainGoldImg,
      supremeMainBlackImg,
      supremeGoldImg1,
      supremeSilverImg1,
      supremeBlackImg1,
      supremeBasicImg1,
      supremeBasicImg2,
      supremeBasicImg3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S12FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S12FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S12FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeMainSilverImg,
    gallery: [
      supremeMainSilverImg,
      supremeMainGoldImg,
      supremeMainBlackImg,
      supremeGoldImg1,
      supremeSilverImg1,
      supremeBlackImg1,
      supremeBasicImg1,
      supremeBasicImg2,
      supremeBasicImg3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S18FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S18FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S18FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeMainSilverImg,
    gallery: [
      supremeMainSilverImg,
      supremeMainGoldImg,
      supremeMainBlackImg,
      supremeGoldImg1,
      supremeSilverImg1,
      supremeBlackImg1,
      supremeBasicImg1,
      supremeBasicImg2,
      supremeBasicImg3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },
  {
    id: 'CH-S24FTXAL',
    categorie: 'split',
    subcategorie: 'Supreme Kontinental',
    title: 'Cooper&Hunter CH-S24FTXAL-WP/SC/GD/BL SUPREME',
    tags: 'Cooper&Hunter CH-S24FTXAL-WP/SC/GD/BL  SUPREME, kontinental',
    image: supremeMainSilverImg,
    gallery: [
      supremeMainSilverImg,
      supremeMainGoldImg,
      supremeMainBlackImg,
      supremeGoldImg1,
      supremeSilverImg1,
      supremeBlackImg1,
      supremeBasicImg1,
      supremeBasicImg2,
      supremeBasicImg3,
    ],
    introduction:
      'Supreme - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Dank der eleganten Blende, die in vier Farben (Weiß / Silber / Gold / Schwarz) erhältlich ist, fügt sich die Klimaanlage perfekt in jedes Interieur ein',
    description: [
      'Plasma-Ionisator',
      '+8 Grad Funktion',
      'Vertikale und horizontale Bewegung der Lamellen',
      'Trockener Kontakt',
      '7 Lüftergeschwindigkeiten',
      'Ein spezieller Arbeitsalgorithmus, der einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen gewährleistet',
    ],
    downloads: supremeKontPDF,
  },

  // NORDIC
  {
    id: 'CH-S09FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S09FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S09FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: [CH09_nordic_1],
    gallery: [CH09_nordic_1, CH09_nordic_2, CH09_nordic_3, CH09_nordic_4],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH-S12FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S12FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S12FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: [CH12_nordic_1],
    gallery: [CH12_nordic_1, CH12_nordic_2, CH12_nordic_3, CH12_nordic_4],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH18FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH-S18FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S18FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },
  {
    id: 'CH24FTXN-E2WF',
    categorie: 'split',
    subcategorie: 'Nordic Evo 2',
    title:
      'Cooper&Hunter Nordic CH24FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH24FTXN-E2WF inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, evo, evo 2',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Nordic Evo 2 ist eine Klimaanlage zum Heizen von Gebäuden. Ein spezieller Algorithmus sorgt für einen stabilen und effizienten Betrieb bei niedrigen Außentemperaturen. Das Gerät nutzt u.a bequem zu bedienender, stufenloser Lüfter und Ionisator, der Staub und Bakterien aus der Luft entfernt.',
    description: [
      'Entwickelt, um bei niedrigen Temperaturen zu arbeiten',
      'Serienmäßig eingebautes WLAN-Modul',
      'Kompressorheizung und Auffangwannenheizung',
      'Hochwertiger Luftionisator',
      'I-Action-Technologie: sanfte Kompressorsteuerung mit einer Frequenz von bis zu 1 Hz',
    ],
    downloads: nordicPDF,
  },

  // VITAL
  {
    id: 'CH-S09FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S09FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S09FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S12FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S12FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S12FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S18FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S18FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S18FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },
  {
    id: 'CH-S24FTXF-NG',
    categorie: 'split',
    subcategorie: 'Vital',
    title:
      'Cooper&Hunter Nordic CH-S24FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät',
    tags: 'Cooper&Hunter Nordic CH-S24FTXF-NG inverter Split Klimaanlage mit Wärmepumpe / Klima Splitgerät, vital',
    image: [CH18_nordic_1],
    gallery: [
      CH18_nordic_1,
      CH18_nordic_2,
      CH18_nordic_3,
      CH18_nordic_4,
      CH18_nordic_5,
    ],
    introduction:
      'Vital Inverter - eine Klimaanlage der neuen Generation, die das ökoeffiziente Kältemittel R32 verwendet. Das wandhängende Klimagerät der Inverter-Serie Vital verfügt über alle Funktionen des auf dem Markt erhältlichen Premium-Gerätebereichs. Es ist mit CH 7-SKY-Filtertechnologie, einem eingebauten WiFi-Modul und einem Plasma-Ionisator ausgestattet.',
    description: [
      'Plasma-Ionisator',
      'Effektiver Arbeitstemperaturbereich von - 15°C bis + 43°C',
      'Eingebautes WIFI-Modul als Standard',
      'Neue Fernbedienung in modernem Design',
      'Die +8-Grad-Funktion hält eine positive Temperatur im Raum aufrecht',
      'Temperatursensor in der Fernbedienung',
      'Die CH 7-SKY-Technologie bedeutet 7 verschiedene Stufen der Luftfilterung.',
    ],
    downloads: vitalPDF,
  },

  // MULTI-INDOOR

  {
    id: 'CHML-S07FTXE-NG',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S07FTXE-NG (I) Alpha Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S07FTXE-NG (I) Alpha Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'SEER A ++ SCOP A ++ full compliance of EU heat pumps and air conditioners for domestic use with the European Union Directive ErP (Energy related Products) No. 626/2011 / EU in force on 01.01.2013',
      'ozone resistant high performance R410 freon',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'multi-speed fan',
      'wide airflow',
      'DAIKIN inverter technology',
      'automatic restart function',
      'frost protection for peripherals',
      '24-hour timer',
      'protection against cold air blowing',
      '“FEEL” function',
      'Working in "UNE" mode',
      'autonomous air drying',
      'color screen',
      'compressor protection',
      'standby mode',
      'Sustainable cooling',
      'Starting from a low outside temperature.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '2.1 / 2.6 kWh' },
      { name: 'Air productivity', value: '500 m³/hour' },
      { name: 'Sound pressure level', value: '38 dB (A)' },
      { name: 'Dimensions (WxDxH)', value: '790x275x200 mm' },
      { name: 'Weight', value: '9 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S09FTXE-NG',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S09FTXE-NG (I) Alpha Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S09FTXE-NG (I) Alpha Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'SEER A ++ SCOP A ++ full compliance of EU heat pumps and air conditioners for domestic use with the European Union Directive ErP (Energy related Products) No. 626/2011 / EU in force on 01.01.2013',
      'ozone resistant high performance R410 freon',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'multi-speed fan',
      'wide airflow',
      'DAIKIN inverter technology',
      'automatic restart function',
      'frost protection for peripherals',
      '24-hour timer',
      'protection against cold air blowing',
      '“FEEL” function',
      'Working in "UNE" mode',
      'autonomous air drying',
      'color screen',
      'compressor protection',
      'standby mode',
      'Sustainable cooling',
      'Starting from a low outside temperature.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '2.6 / 2.8 kWh' },
      { name: 'Air productivity', value: '560 m³/hour' },
      { name: 'Sound pressure level', value: '38 dB (A)' },
      { name: 'Dimensions (WxDxH)', value: '790x275x200 mm' },
      { name: 'Weight', value: '9 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S12FTXE-NG',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S12FTXE-NG (I) Alpha Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S12FTXE-NG (I) Alpha Multi Indoor unit',
    image: CHMLS09_3,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'SEER A ++ SCOP A ++ full compliance of EU heat pumps and air conditioners for domestic use with the European Union Directive ErP (Energy related Products) No. 626/2011 / EU in force on 01.01.2013',
      'ozone resistant high performance R410 freon',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'multi-speed fan',
      'wide airflow',
      'DAIKIN inverter technology',
      'automatic restart function',
      'frost protection for peripherals',
      '24-hour timer',
      'protection against cold air blowing',
      '“FEEL” function',
      'Working in "UNE" mode',
      'autonomous air drying',
      'color screen',
      'compressor protection',
      'standby mode',
      'Sustainable cooling',
      'Starting from a low outside temperature.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '3.2 / 3.5 kWh' },
      { name: 'Air productivity', value: '560 m³/hour' },
      { name: 'Sound pressure level', value: '38 dB (A)' },
      { name: 'Dimensions (WxDxH)', value: '790x275x200 mm' },
      { name: 'Weight', value: '9 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S18FTXE-NG',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S18FTXE-NG (I) Alpha Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S18FTXE-NG (I) Alpha Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'SEER A ++ SCOP A ++ full compliance of EU heat pumps and air conditioners for domestic use with the European Union Directive ErP (Energy related Products) No. 626/2011 / EU in force on 01.01.2013',
      'ozone resistant high performance R410 freon',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'multi-speed fan',
      'wide airflow',
      'DAIKIN inverter technology',
      'automatic restart function',
      'frost protection for peripherals',
      '24-hour timer',
      'protection against cold air blowing',
      '“FEEL” function',
      'Working in "UNE" mode',
      'autonomous air drying',
      'color screen',
      'compressor protection',
      'standby mode',
      'Sustainable cooling',
      'Starting from a low outside temperature.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '4.6 / 5.2 kWh' },
      { name: 'Air productivity', value: '850 m³/hour' },
      { name: 'Sound pressure level', value: '44 dB (A)' },
      { name: 'Dimensions (WxDxH)', value: '970x300x224 mm' },
      { name: 'Weight', value: '13.5 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S09FTXQ',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S09FTXQ (I) Veritas Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S09FTXQ (I) Veritas Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'CH 7-SKY Technology is a complex filtration system based on seven broad-spectrum filters',
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet (operating system: Android, iOS)',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'High-quality energy-saving GENERATON IV equipment (better quality control of assembly and materials)',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'self-cleaning indoor unit',
      'Premium remote control with new ergonomic body, night lighting and germicidal coating',
      '“FEEL” function - built-in temperature sensor on the remote control.  Accuracy of maintaining a temperature of 0,5 ° C',
      '"I-Action" technology - smooth and stable operation of the compressor at extremely low frequencies (1Hz)',
      'Uninterrupted operation between 96V-260V. Innovative, compact transformer',
      'Protect your home from freezing: the "+8 degree" function. The air conditioner maintains a temperature of 8 ° C, preventing the room from freezing and consuming minimal electricity;Wide-angle blinds that cover the entire volume of the room',
      'frost protection for peripherals',
      '24-hour timer; BLUE-FIN cover',
      'Comfortable temperature maintenance in “SLEEP” night mode',
      'autonomous air drying',
      'compressor protection',
      'starting from a low outside temperature',
      'easy to assemble housing for quick installation and cleaning',
      '"Automatic restart" function - automatic restart with settings saved',
      'Self-diagnosis of malfunctions of basic units and modes, absolute protection against incorrect user actions, indicating an error on the screen',
      'SEER A ++ of the European Union Directive valid on 01.01.2013 for energy heat pumps and air conditioners for household use ErP (Energy related products) No. 626/2011 / EU full compliance.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '2.5 / 2.8 kWh' },
      { name: 'Air productivity', value: '480/370/320/210 m³/hour' },
      { name: 'Sound pressure level', value: '40/36/34/29 dB(A)' },
      { name: 'Dimensions (WxDxH)', value: '790x200x275 mm' },
      { name: 'Weight', value: '9 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S12FTXQ',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S12FTXQ (I) Veritas Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S12FTXQ (I) Veritas Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'CH 7-SKY Technology is a complex filtration system based on seven broad-spectrum filters',
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet (operating system: Android, iOS)',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'High-quality energy-saving GENERATON IV equipment (better quality control of assembly and materials)',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'self-cleaning indoor unit',
      'Premium remote control with new ergonomic body, night lighting and germicidal coating',
      '“FEEL” function - built-in temperature sensor on the remote control. Accuracy of maintaining a temperature of 0,5 ° C',
      '"I-Action" technology - smooth and stable operation of the compressor at extremely low frequencies (1Hz)',
      'Uninterrupted operation between 96V-260V. Innovative, compact transformer',
      'Protect your home from freezing: the "+8 degree" function. The air conditioner maintains a temperature of 8 ° C, preventing the room from freezing and consuming minimal electricity;Wide-angle blinds that cover the entire volume of the room',
      'frost protection for peripherals',
      '24-hour timer; BLUE-FIN cover',
      'Comfortable temperature maintenance in “SLEEP” night mode',
      'autonomous air drying',
      'compressor protection',
      'starting from a low outside temperature',
      'easy to assemble housing for quick installation and cleaning',
      '"Automatic restart" function - automatic restart with settings saved',
      'Self-diagnosis of malfunctions of basic units and modes, absolute protection against incorrect user actions, indicating an error on the screen',
      'SEER A ++ of the European Union Directive valid on 01.01.2013 for energy heat pumps and air conditioners for household use ErP (Energy related products) No. 626/2011 / EU full compliance.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '3.2 / 3.4 kWh' },
      { name: 'Air productivity', value: '560/480/410/290 m³/hour' },
      { name: 'Sound pressure level', value: '42/37/34/28 dB(A)' },
      { name: 'Dimensions (WxDxH)', value: '790x200x275 mm' },
      { name: 'Weight', value: '9 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S18FTXQ',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S18FTXQ (I) Veritas Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S18FTXQ (I) Veritas Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'CH 7-SKY Technology is a complex filtration system based on seven broad-spectrum filters',
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet (operating system: Android, iOS)',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'High-quality energy-saving GENERATON IV equipment (better quality control of assembly and materials)',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'self-cleaning indoor unit',
      'Premium remote control with new ergonomic body, night lighting and germicidal coating',
      '“FEEL” function - built-in temperature sensor on the remote control.Accuracy of maintaining a temperature of 0,5 ° C',
      '"I-Action" technology - smooth and stable operation of the compressor at extremely low frequencies (1Hz)',
      'Uninterrupted operation between 96V-260V. Innovative, compact transformer',
      'Protect your home from freezing: the "+8 degree" function. The air conditioner maintains a temperature of 8 ° C, preventing the room from freezing and consuming minimal electricity;Wide-angle blinds that cover the entire volume of the room',
      'frost protection for peripherals',
      '24-hour timer; BLUE-FIN cover',
      'Comfortable temperature maintenance in “SLEEP” night mode',
      'autonomous air drying',
      'compressor protection',
      'starting from a low outside temperature',
      'easy to assemble housing for quick installation and cleaning',
      '"Automatic restart" function - automatic restart with settings saved',
      'Self-diagnosis of malfunctions of basic units and modes, absolute protection against incorrect user actions, indicating an error on the screen',
      'SEER A ++ of the European Union Directive valid on 01.01.2013 for energy heat pumps and air conditioners for household use ErP (Energy related products) No. 626/2011 / EU full compliance.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '4.6 / 5 kWh' },
      { name: 'Air productivity', value: '850/720/610/520 m³/hour' },
      { name: 'Sound pressure level', value: '45/41/37/33 dB(A)' },
      { name: 'Dimensions (WxDxH)', value: '970x224x300 mm' },
      { name: 'Weight', value: '13.5 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
    ],
    downloads: catalogue,
  },

  {
    id: 'CHML-S24FTXQ',
    categorie: 'multi',
    subcategorie: 'indoor',
    title: 'Cooper&Hunter CHML-S24FTXQ (I) Veritas Multi Indoor unit',
    tags: 'Cooper&Hunter CHML-S24FTXQ (I) Veritas Multi Indoor unit',
    image: CHMLS09,
    gallery: [CHMLS09, CHMLS09_1, CHMLS09_2, CHMLS09_3],
    description: [
      'CH 7-SKY Technology is a complex filtration system based on seven broad-spectrum filters',
      'Wi-Fi module for controlling the air conditioner via smartphone / tablet (operating system: Android, iOS)',
      '“CH SMART-ION Filter” - a new generation of air purification technology',
      'High-quality energy-saving GENERATON IV equipment (better quality control of assembly and materials)',
      'advanced software that optimizes the operation of the air conditioner over a wide range of compressors',
      'self-cleaning indoor unit',
      'Premium remote control with new ergonomic body, night lighting and germicidal coating',
      '“FEEL” function - built-in temperature sensor on the remote control.Accuracy of maintaining a temperature of 0,5 ° C',
      '"I-Action" technology - smooth and stable operation of the compressor at extremely low frequencies (1Hz)',
      'Uninterrupted operation between 96V-260V. Innovative, compact transformer',
      'Protect your home from freezing: the "+8 degree" function. The air conditioner maintains a temperature of 8 ° C, preventing the room from freezing and consuming minimal electricity;Wide-angle blinds that cover the entire volume of the room',
      'frost protection for peripherals',
      '24-hour timer; BLUE-FIN cover',
      'Comfortable temperature maintenance in “SLEEP” night mode',
      'autonomous air drying',
      'compressor protection',
      'starting from a low outside temperature',
      'easy to assemble housing for quick installation and cleaning',
      '"Automatic restart" function - automatic restart with settings saved',
      'Self-diagnosis of malfunctions of basic units and modes, absolute protection against incorrect user actions, indicating an error on the screen',
      'SEER A ++ of the European Union Directive valid on 01.01.2013 for energy heat pumps and air conditioners for household use ErP (Energy related products) No. 626/2011 / EU full compliance.',
    ],
    data: [
      { name: 'Productivity Cold / Warm', value: '6.7 / 7.25 kWh' },
      { name: 'Air productivity', value: '1150/1050/950/850 m³/hour' },
      { name: 'Sound pressure level', value: '48/45/42/39 dB(A)' },
      { name: 'Dimensions (WxDxH)', value: '1078x246x325 mm' },
      { name: 'Weight', value: '17 Kg' },
      { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
      { name: 'Pipeline diameter', value: '15.88mm / 5/8 ”' },
    ],
    downloads: catalogue,
  },

  // {
  //   id: 'CH-S09FTXAM2S',
  //   categorie: 'multi',
  //   subcategorie: 'Supreme indoor',
  //   title:
  //     'Cooper&Hunter CH-S09FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   tags: 'Cooper&Hunter CH-S09FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   image: supremeMainSilverImg,
  //   gallery: [
  //     supremeMainSilverImg,
  //     supremeMainGoldImg,
  //     supremeMainBlackImg,
  //     supremeGoldImg1,
  //     supremeSilverImg1,
  //     supremeBlackImg1,
  //     supremeBasicImg1,
  //     supremeBasicImg2,
  //     supremeBasicImg3,
  //   ],
  //   description: [
  //     'Household heat pump. Adapted for thermal works in northern European countries',
  //     'Home frost protection + 8 ° C',
  //     '“CH SMART - ION Filter” - a new generation of air purification technology',
  //     'Wi-Fi module for controlling air conditioners via smartphone / tablet (operating system: Android, iOS)',
  //     'Extremely low noise level - from 18 dB, one-piece plastic housing and new seamless heat exchanger technology',
  //     'multi-speed fan',
  //     'increased labor resources',
  //     'Display of the current time',
  //     'Self-cleaning of the internal heat exchanger. After stopping the air conditioner, the fan does not stop and removes moisture from the heat exchanger, which prevents the growth of mold, fungi and bacteria inside the unit',
  //     'The set includes two filters to choose from (optional): activated carbon anti-odor filter; filter "Antibacterial suppressors"; Nanotitanium anti-chemical catalyst filter; Catechin filter',
  //     'Electrostatic air filter ECO-FRESH',
  //     'Stylish LED display on the inside panel. On / off screen with remote control',
  //     'SLEEP comfortable sleep mode (several options for manual adjustment)',
  //     'Smart control in AUTO mode - automatic change of operating modes depending on changes in room temperature',
  //     '24-hour timer on and off',
  //     'Delayed start of the indoor fan to prevent cold air from blowing in heating mode',
  //     'Remote control with bactericidal coating',
  //     'Anti - corrosion coated heat exchangers GREEN - FIN',
  //     'Automatic restart. The device will operate in the same mode as before the power was turned off or the power was turned off',
  //     'intelligent anti-icing system',
  //     'Device self-diagnostics',
  //     'Steering lock.',
  //   ],
  //   data: [
  //     { name: 'Productivity Cold / Warm', value: '2.7 / 3.5 kWh' },
  //     {
  //       name: 'Air productivity',
  //       value: '800/720/570/620/560/500/430 m³/hour',
  //     },
  //     { name: 'Sound pressure level', value: '43/41/38/36/33/31/18 dB(A)' },
  //     { name: 'Dimensions (WxDxH)', value: '996X301X225 mm' },
  //     { name: 'Weight', value: '10.5 Kg' },
  //     { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
  //     { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
  //   ],
  //   downloads: catalogue,
  // },

  // {
  //   id: 'CH-S12FTXAM2S',
  //   categorie: 'multi',
  //   subcategorie: 'Supreme indoor',
  //   title:
  //     'Cooper&Hunter CH-S12FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   tags: 'Cooper&Hunter CH-S12FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   image: supremeMainSilverImg,
  //   gallery: [
  //     supremeMainSilverImg,
  //     supremeMainGoldImg,
  //     supremeMainBlackImg,
  //     supremeGoldImg1,
  //     supremeSilverImg1,
  //     supremeBlackImg1,
  //     supremeBasicImg1,
  //     supremeBasicImg2,
  //     supremeBasicImg3,
  //   ],
  //   description: [
  //     'Household heat pump. Adapted for thermal works in northern European countries',
  //     'Home frost protection + 8 ° C',
  //     '“CH SMART - ION Filter” - a new generation of air purification technology',
  //     'Wi-Fi module for controlling air conditioners via smartphone / tablet (operating system: Android, iOS)',
  //     'Extremely low noise level - from 18 dB, one-piece plastic housing and new seamless heat exchanger technology',
  //     'multi-speed fan',
  //     'increased labor resources',
  //     'Display of the current time',
  //     'Self-cleaning of the internal heat exchanger. After stopping the air conditioner, the fan does not stop and removes moisture from the heat exchanger, which prevents the growth of mold, fungi and bacteria inside the unit',
  //     'The set includes two filters to choose from (optional): activated carbon anti-odor filter; filter "Antibacterial suppressors"; Nanotitanium anti-chemical catalyst filter; Catechin filter',
  //     'Electrostatic air filter ECO-FRESH',
  //     'Stylish LED display on the inside panel. On / off screen with remote control',
  //     'SLEEP comfortable sleep mode (several options for manual adjustment)',
  //     'Smart control in AUTO mode - automatic change of operating modes depending on changes in room temperature',
  //     '24-hour timer on and off',
  //     'Delayed start of the indoor fan to prevent cold air from blowing in heating mode',
  //     'Remote control with bactericidal coating',
  //     'Anti - corrosion coated heat exchangers GREEN - FIN',
  //     'Automatic restart. The device will operate in the same mode as before the power was turned off or the power was turned off',
  //     'intelligent anti-icing system',
  //     'Device self-diagnostics',
  //     'Steering lock.',
  //   ],
  //   data: [
  //     { name: 'Productivity Cold / Warm', value: '3.53 / 4.2 kWh' },
  //     {
  //       name: 'Air productivity',
  //       value: '800/730/680/630/580/530/450 m³/hour',
  //     },
  //     { name: 'Sound pressure level', value: '46/43/41/38/36/34/20 dB(A)' },
  //     { name: 'Dimensions (WxDxH)', value: '996X301X225 mm' },
  //     { name: 'Weight', value: '11 Kg' },
  //     { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
  //     { name: 'Pipeline diameter', value: '9.53mm / 3/8 ”' },
  //   ],
  //   downloads: catalogue,
  // },

  // {
  //   id: 'CH-S18FTXAM2S',
  //   categorie: 'multi',
  //   subcategorie: 'Supreme indoor',
  //   title:
  //     'Cooper&Hunter CH-S18FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   tags: 'Cooper&Hunter CH-S18FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   image: supremeMainSilverImg,
  //   gallery: [
  //     supremeMainSilverImg,
  //     supremeMainGoldImg,
  //     supremeMainBlackImg,
  //     supremeGoldImg1,
  //     supremeSilverImg1,
  //     supremeBlackImg1,
  //     supremeBasicImg1,
  //     supremeBasicImg2,
  //     supremeBasicImg3,
  //   ],
  //   description: [
  //     'Household heat pump. Adapted for thermal works in northern European countries',
  //     'Home frost protection + 8 ° C',
  //     '“CH SMART - ION Filter” - a new generation of air purification technology',
  //     'Wi-Fi module for controlling air conditioners via smartphone / tablet (operating system: Android, iOS)',
  //     'Extremely low noise level - from 18 dB, one-piece plastic housing and new seamless heat exchanger technology',
  //     'multi-speed fan',
  //     'increased labor resources',
  //     'Display of the current time',
  //     'Self-cleaning of the internal heat exchanger. After stopping the air conditioner, the fan does not stop and removes moisture from the heat exchanger, which prevents the growth of mold, fungi and bacteria inside the unit',
  //     'The set includes two filters to choose from (optional): activated carbon anti-odor filter; filter "Antibacterial suppressors"; Nanotitanium anti-chemical catalyst filter; Catechin filter',
  //     'Electrostatic air filter ECO-FRESH',
  //     'Stylish LED display on the inside panel. On / off screen with remote control',
  //     'SLEEP comfortable sleep mode (several options for manual adjustment)',
  //     'Smart control in AUTO mode - automatic change of operating modes depending on changes in room temperature',
  //     '24-hour timer on and off',
  //     'Delayed start of the indoor fan to prevent cold air from blowing in heating mode',
  //     'Remote control with bactericidal coating',
  //     'Anti - corrosion coated heat exchangers GREEN - FIN',
  //     'Automatic restart. The device will operate in the same mode as before the power was turned off or the power was turned off',
  //     'intelligent anti-icing system',
  //     'Device self-diagnostics',
  //     'Steering lock.',
  //   ],
  //   data: [
  //     { name: 'Productivity Cold / Warm', value: '5.3 / 5.57 kWh' },
  //     { name: 'Air productivity', value: '1200/1150/950/780 m³/hour' },
  //     { name: 'Sound pressure level', value: '48/45/43/40/37/35/33 dB(A)' },
  //     { name: 'Dimensions (WxDxH)', value: '1101X327X249 mm' },
  //     { name: 'Weight', value: '16.5 Kg' },
  //     { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
  //     { name: 'Pipeline diameter', value: '15.88mm / 5/8 ”' },
  //   ],
  //   downloads: catalogue,
  // },

  // {
  //   id: 'CH-S24FTXAM2S',
  //   categorie: 'multi',
  //   subcategorie: 'Supreme indoor',
  //   title:
  //     'Cooper&Hunter CH-S24FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   tags: 'Cooper&Hunter CH-S24FTXAM2S-BL/GD/SC/WP (I) SUPREME Multi Indoor unit',
  //   image: supremeMainSilverImg,
  //   gallery: [
  //     supremeMainSilverImg,
  //     supremeMainGoldImg,
  //     supremeMainBlackImg,
  //     supremeGoldImg1,
  //     supremeSilverImg1,
  //     supremeBlackImg1,
  //     supremeBasicImg1,
  //     supremeBasicImg2,
  //     supremeBasicImg3,
  //   ],
  //   description: [
  //     'Household heat pump. Adapted for thermal works in northern European countries',
  //     'Home frost protection + 8 ° C',
  //     '“CH SMART - ION Filter” - a new generation of air purification technology',
  //     'Wi-Fi module for controlling air conditioners via smartphone / tablet (operating system: Android, iOS)',
  //     'Extremely low noise level - from 18 dB, one-piece plastic housing and new seamless heat exchanger technology',
  //     'multi-speed fan',
  //     'increased labor resources',
  //     'Display of the current time',
  //     'Self-cleaning of the internal heat exchanger. After stopping the air conditioner, the fan does not stop and removes moisture from the heat exchanger, which prevents the growth of mold, fungi and bacteria inside the unit',
  //     'The set includes two filters to choose from (optional): activated carbon anti-odor filter; filter "Antibacterial suppressors"; Nanotitanium anti-chemical catalyst filter; Catechin filter',
  //     'Electrostatic air filter ECO-FRESH',
  //     'Stylish LED display on the inside panel. On / off screen with remote control',
  //     'SLEEP comfortable sleep mode (several options for manual adjustment)',
  //     'Smart control in AUTO mode - automatic change of operating modes depending on changes in room temperature',
  //     '24-hour timer on and off',
  //     'Delayed start of the indoor fan to prevent cold air from blowing in heating mode',
  //     'Remote control with bactericidal coating',
  //     'Anti - corrosion coated heat exchangers GREEN - FIN',
  //     'Automatic restart. The device will operate in the same mode as before the power was turned off or the power was turned off',
  //     'intelligent anti-icing system',
  //     'Device self-diagnostics',
  //     'Steering lock.',
  //   ],
  //   data: [
  //     { name: 'Productivity Cold / Warm', value: '7 / 7 kWh' },
  //     { name: 'Air productivity', value: '1250/1100/950/850 m³/hour' },
  //     { name: 'Sound pressure level', value: '50/46/43/41/39/37/35/27 dB(A)' },
  //     { name: 'Dimensions (WxDxH)', value: '1101X327X249 mm' },
  //     { name: 'Weight', value: '16.5 Kg' },
  //     { name: 'The diameter of the liquid', value: '6.35mm / 1/4 ”' },
  //     { name: 'Pipeline diameter', value: '15.88mm / 5/8 ”' },
  //   ],
  //   downloads: catalogue,
  // },

  // MULTI-OUTDOOR

  {
    id: 'CHML-U14RK2',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML-U14RK2 14000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML-U14RK2 14000BTU/H',
    image: CHMLU14,
    gallery: [],
    heating: '4,4',
    cooling: '4,1',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U18RK3',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML- U18RK3 18000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML- U18RK3 18000BTU/H',
    image: CHMLU18,
    gallery: [],
    heating: '5,7',
    cooling: '5,3',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U21RK3',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML- U21RK3 21000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML- U21RK3 21000BTU/H',
    image: CHMLU21,
    gallery: [],
    heating: '6,5',
    cooling: '6,1',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U24RK3',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML- U24RK3 24000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML- U24RK3 24000BTU/H',
    image: CHMLU24,
    gallery: [],
    heating: '8,5',
    cooling: '7,03',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U28RK4',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML- U28RK4 28000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML- U28RK4 28000BTU/H',
    image: CHMLU28,
    gallery: [],
    heating: '9,38',
    cooling: '8',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U36RK4',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML-U36RK4 36000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML-U36RK4 36000BTU/H',
    image: CHMLU36,
    gallery: [],
    heating: '11',
    cooling: '9,8',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  {
    id: 'CHML-U42RK5',
    categorie: 'multi',
    subcategorie: 'outdoor',
    title: 'NORDIC MULTI LIGHT OUTDOOR CHML-U42RK5 42000BTU/H',
    tags: 'NORDIC MULTI LIGHT OUTDOOR CHML-U42RK5 42000BTU/H',
    image: CHMLU42,
    gallery: [],
    heating: '13',
    cooling: '11,58',
    description: [
      'The operating range of the system is 50-150% of the rated power of the outdoor unit',
      'Safe starting in the range from 96 V to 260 V',
      'Stable Frequency Control technology - smooth and stable operation at ultra-low frequencies (15 Hz)',
      'Noise Analysis Technology - almost silent operation of indoor and outdoor units',
      'Wide temperature range of effective operation: from -20 ° C for heating and up to + 48 ° C for cooling',
      'New generation Intelligent Preheating automatic anti-icing system. Unlike the usual “timed” defrosting scheme - on average 10 minutes of defrosting for 50 minutes of compressor operation, the defrosting process is started only when there is a real need. This state-of-the-art technology obviously reduces the energy loss for unnecessary defrost cycles',
      'Energy efficiency class "A".',
    ],
    downloads: multi_outdoor,
  },

  // KANALISIERTE

  {
    id: 'CH-ID035RKE - CH-IU035RKE',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-ID035RKE / CH-IU035RKE Kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-ID035RKE / CH-IU035RKE Kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  {
    id: 'CH-ID050RKE - CH-IU050RKE',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-ID050RKE / CH-IU050RKE Kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-ID050RKE / CH-IU050RKE Kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  {
    id: 'CH-ID071RKE - CH-IU071RKE',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-ID071RKE / CH-IU071RKE Kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-ID071RKE / CH-IU071RKE Kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  {
    id: 'CH-ID100RKE - CH-IU100RKE',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-ID100RKE / CH-IU100RKE Kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-ID100RKE / CH-IU100RKE Kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  {
    id: 'CH-ID140RKE - CH-IU140RME',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-CH-ID140RKE / CH-IU140RME Kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-CH-ID140RKE / CH-IU140RME Kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  {
    id: 'CH-ID160RKE - CH-IU160RME',
    categorie: 'kanalisierte',
    subcategorie: 'Indoor/Outdoor',
    title:
      'Cooper&Hunter CH-ID160RKE - CH-IU160RME kanalisierte indoor / outdoor',
    tags: 'Cooper&Hunter CH-ID160RKE - CH-IU160RME kanalisierte indoor / outdoor',
    image: kanalIndoor,
    gallery: [kanalIndoor, kanalOutdoor, remoteController],
    description: [
      'Kompakte Größe',
      'Entwässerungspumpe',
      'Niedriger Geräuschpegel',
      'Langlebiger, waschbarer Filter',
      'Selbstdiagnose der Hauptgeräte und',
      'Modi Intelligentes Abtauen',
      'Mehrstufiges Schutzsystem',
      'Bis zu 75m Rohrlänge',
      'Timer',
      'Automatischer Neustart',
      'Geräuschloser Betrieb',
      'Schlafmodus',
      'Entfeuchten',
      'Mehrfachgeschwindigkeitsventilator',
      'Intelligentes Abtauen',
      'Selbstdiagnose',
      'Auto-Schutz',
      'Garantie',
      'Kältemitteltyp',
      'Hält die Raumtemperatur konstant bei +8°',
      'A++ Energieeffizienzklasse',
      '„I Feel“Temperatursteuerungmittels Detektor auf der Fernbedienung',
    ],
    downloads: kanalPDF,
  },

  // KASETTEN
  {
    id: 'CH-IC035RKE - CH-IU035RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC035RKE / CH-IU035RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC035RKE / CH-IU035RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },
  {
    id: 'CH-IC050RKE - CH-IU050RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC050RKE / CH-IU050RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC050RKE / CH-IU050RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },
  {
    id: 'CH-IC071RKE - CH-IU071RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC071RKE - CH-IU071RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC071RKE - CH-IU071RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },
  {
    id: 'CH-IC100RKE - CH-IU100RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC100RKE / CH-IU100RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC100RKE / CH-IU100RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },

  {
    id: 'CH-IC140RKE - CH-IU140RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC140RKE / CH-IU140RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC140RKE / CH-IU140RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },
  {
    id: 'CH-IC160RKE - CH-IU160RKE',
    categorie: 'kassetten',
    subcategorie: 'Indoor/Outdoor',
    title: 'Cooper&Hunter CH-IC160RKE / CH-IU160RKE Kassetten indoor / outdoor',
    tags: 'Cooper&Hunter CH-IC160RKE / CH-IU160RKE Kassetten indoor / outdoor',
    image: kassetten1Img,
    gallery: [kassetten1Img, kassetten2Img, kassetten3Img],
    description: [
      'Compact size',
      'Drainage pump (only for inverter models)',
      'Low noise level',
      'Long life washable filter',
      'Automatic air distribution in Swing mode',
      'Self-diagnosis of the main units and modes',
      'Multi-level protection system',
      'Length of the pipe up to 50 m',
    ],
    downloads: kassettenPDF,
  },

  // WARMEPUMPEN
  {
    id: 'Ecopower Serie',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter - Ecopower Serie',
    tags: 'wärmepumpen, Ecopower, CH-HP9.0UIMPZK, CH-HP15IUMPZK, CH-HP15UIMPZM, CH-HP22UIMPZK, CH-HP22UIMPZM, warmepumpen',
    image: ecoMainImg,
    gallery: [ecoMainImg, ecoImg1, ecoImg2, ecoImg3],
    description: [
      'Optimized airflow and heat transfer to reduce noise and increase efficiency',
      'New unique design and extremely low noise pressure',
      'New 290 freon, extremely high energy efficiency A+++',
      'The maximum outlet water temperature is 70°C',
      'Water and freon temperature sensors are monitored in real time, which protects the plate heat exchanger',
      'Distributer that is inside the plate heat exchanger allows to provide channel with the same flow and reduces the risk of freezing',
      'The water flow switch and circulation pump protect heat pump from insufficient flow through heat exchanger',
      'When the water temperature is too low, the defrost mode will not be activated to reduce the possibility of freezing of the plate heat exchanger.',
      'Remote control by a smartphone, BMS smart home and a central server are from the manufacturer',
    ],
    data: [
      { name: 'Model', value: 'Capacity (Cooling/Heating)' },
      {
        name: 'CH-HP9.0UIMPZK',
        value: 'Cooling: 1,2-5,72kW / Heating: 3,1-8,9kW',
      },
      {
        name: 'CH-HP15IUMPZK',
        value: 'Cooling: 3,6-10,5kW / Heating: 5,4-14,95kW',
      },
      {
        name: 'CH-HP15UIMPZM',
        value: 'Cooling: 3,6-10,5kW / Heating: 5,4-14,95kW',
      },
      { name: 'CH-HP22UIMPZK', value: 'Cooling: 4,2-15kW / Heating: 8-22kW' },
      { name: 'CH-HP22UIMPZM', value: 'Cooling: 4,2-15kW / Heating: 8-22kW' },
    ],
    downloads: warmeEcoPDF,
  },
  {
    id: 'Heating, cooling and HWS',
    categorie: 'warmepumpen',
    justPdf: true,
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter - Wärmepumpen 2022 product line',
    tags: 'wärmepumpen, Evipower, Inverter, 2022, warmepumpen',
    image: basicMainImg,
    downloads: warmeBasicPDF,
  },
  {
    id: 'Evipower Inverter Serie',
    categorie: 'warmepumpen',
    subcategorie: 'Wärmepumpen',
    title: 'Cooper&Hunter - Evipower Inverter Serie',
    tags: 'wärmepumpen, Evipower, Inverter, AIR-TO-WATER HEAT PUMP FOR COOL- ING HEATING AND DHW, CH-HP08UIMPRK, CH-HP12UIMPRK, CH-HP20UIMPRK, warmepumpen',
    image: eviMainImg,
    gallery: [eviMainImg, eviImg1, eviImg2, eviImg3],
    description: [
      'User friendly 5` sensor display of a wired control',
      '4G MMN (Management and Monitoring Network)',
      'Weekly timer',
      'Tube in shell heat exchanger',
      'Water pump Grundfos',
      'Panasonic EVI Invertor compressor',
      'Efficient antifreezing system',
      'Low temperature start system',
    ],
    data: [
      { name: 'Model', value: 'Capacity (Cooling/Heating)' },
      { name: 'CH-HP08UIMPRK', value: 'Cooling: 6.1kW / Heating: 8.2kW' },
      { name: 'CH-HP12UIMPRM', value: 'Cooling: 10.5kW / Heating: 13kW' },
      { name: 'CH-HP20UIMPRM', value: 'Cooling: 14kW / Heating: 20kW' },
    ],
    downloads: warmeEviPDF,
  },

  // HEATPUMPS - POOL
  {
    id: 'Eco Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Schwimmbad pool wasser warmepumpen',
    title: 'Turbo Inverter Serie - Eco Serie',
    tags: 'Turbo Inverter Series - Eco Series schwimmbad pool wasser Titanium warmepumpen CH-HP010LERK CH-HP015LERK CH-HP020LERK CH-HP030LERK CH-HP035LERK CH-HP040LERK CH-HP045LERK',
    image: pool2,
    gallery: [pool2],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      'Intelligentes Bedienfeld',
      'Hohe Effizienz',
      'Betriebstemperaturbereich von -7°C to +43°C',
      'Verwendung: Schwimmbäder bis 78.m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP010LERK', value: '17 m³' },
      { name: 'CH-HP015LERK', value: '25 m³' },
      { name: 'CH-HP020LERK', value: '35 m³' },
      { name: 'CH-HP030LERK', value: '45 m³' },
      { name: 'CH-HP035LERK', value: '58 m³' },
      { name: 'CH-HP040LERK', value: '65 m³' },
      { name: 'CH-HP045LERK', value: '78 m³' },
    ],
    downloads: poolPDF,
  },
  {
    id: 'Turbo Inverter Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Schwimmbad pool wasser warmepumpen',
    title: 'Cooper&Hunter - Turbo Inverter Serie',
    tags: 'Cooper&Hunter - Turbo Inverter Series schwimmbad pool wasser Titanium warmepumpen CH-HP050LTIRK CH-HP050LTIRM CH-HP060LTIRK CH-HP060LTIRM CH-HP070LTIRK CH-HP070LTIRM CH-HP080LTIRK HP080LTIRM',
    image: pool1,
    gallery: [pool1],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      '5 Zoll Touch-Bedienfeld',
      'Hohe Effizienz',
      'Wi-Fi-Fernsteuerung',
      'Vertikaler Luftauslass',
      'Wenig Lärm',
      'Intelligentes Abtausystem',
      'Hohe Genauigkeit der Temperaturhaltung',
      'Betriebstemperaturbereich von -15°C to +43°C',
      'Verwendung: Schwimmbäder bis 136 m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP050LTIRK', value: '42-84 m³' },
      { name: 'CH-HP050LTIRM', value: '42-84 m³' },
      { name: 'CH-HP060LTIRK', value: '50-100 m³' },
      { name: 'CH-HP060LTIRM', value: '50-100 m³' },
      { name: 'CH-HP070LTIRK', value: '58-116 m³' },
      { name: 'CH-HP070LTIRM', value: '60-120 m³' },
      { name: 'CH-HP080LTIRK', value: '68-136 m³' },
      { name: 'HP080LTIRM', value: '68-136 m³' },
    ],
    downloads: poolPDF,
  },
  {
    id: 'Boost Inverter Serie',
    categorie: 'schwimmbad-pool-wasser-warmepumpen',
    subcategorie: 'Schwimmbad pool wasser warmepumpen',
    title: 'Cooper&Hunter - Boost Inverter Serie ',
    tags: 'Cooper&Hunter - Boost Inverter Series schwimmbad pool wasser Titanium warmepumpen CH-HP050LBIRK CH-HP060LBIRK CH-HP075LBIRK CH-HP090LBIRK CH-HP120LBIRK CH-HP169LBIRK CH-HP095LBIRM CH-HP120LBIRM CH-HP169LBIRM',
    image: pool3,
    gallery: [pool3],
    description: [
      'Wärmetauscher aus Titan',
      'Ozonsicheres Kältemittel R32',
      '5 Zoll Touch-Bedienfeld',
      'Hohe Effizienz',
      'Wi-Fi-Fernsteuerung',
      'Hohe Genauigkeit der Temperaturhaltung',
      'Betriebstemperaturbereich von -15°C to +43°C',
      'Verwendung: Schwimmbäder bis to 169 m3',
    ],
    data: [
      { name: 'Model', value: 'Epfohlenes Poolvolumen' },
      { name: 'CH-HP050LBIRK', value: '25-50 m³' },
      { name: 'CH-HP060LBIRK', value: '30-60 m³' },
      { name: 'CH-HP075LBIRK', value: '40-75 m³' },
      { name: 'CH-HP095LBIRK', value: '50-95 m³' },
      { name: 'CH-HP120LBIRК', value: '65-120 m³' },
      { name: 'CH-HP169LBIRK', value: '90-169 m³' },
      { name: 'CH-HP095LBIRM', value: '50-95 m³' },
      { name: 'CH-HP120LBIRM', value: '65-120 m³' },
      { name: 'CH-HP169LBIRM', value: '90-169 m³' },
    ],
    downloads: poolPDF,
  },
]

export default products
