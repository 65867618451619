import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

// images
import contactBg from '../assets/images/contact.webp'
import contactBgMobile from '../assets/images/contact-mobile.webp'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='contact-hero'>
        <div className='content-wrapper inner'>
          <h2>{t('kapcsolat')}</h2>
          <h1>{t('carousel-h2')}</h1>
        </div>
      </div>
      <div className='content-wrapper'>
        <div className='content-container'>
          <div className='left'>
            <div className='content-title'>
              <h3>{t('contact-title')}</h3>
              <hr />
            </div>
            <div className='row-1'>
              <div className='icon-box'>
                <span className='material-symbols-outlined'>map</span>
                <h4 className='icon-title'>{t('address')}</h4>
                <p>{t('address-data')}</p>
              </div>
              <div className='icon-box'>
                <a href='tel:+436642174381'>
                  <span className='material-symbols-outlined'>
                    phone_iphone
                  </span>
                  <h4 className='icon-title'>{t('phone')}</h4>
                  <p>{t('phone-data')}</p>
                </a>
              </div>
            </div>
            <div className='row-2'>
              {/* <div className='icon-box'>
                <img src={fax} alt='fax-icon' />
                <h4 className='icon-title'>{t('fax')}</h4>
                <p>{t('fax-data')}</p>
              </div> */}
              <div className='icon-box'>
                <a href='mailto:office@cooperklima.at'>
                  <span className='material-symbols-outlined'>mail</span>
                  <h4 className='icon-title'>{t('mail')}</h4>
                  <p>{t('mail-data')}</p>
                </a>
              </div>
            </div>
          </div>
          <div className='right-map'>
            <iframe
              width='100%'
              height='100%'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              title='map'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.3908878621537!2d16.195902915998634!3d48.141268879223766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da7a3a2adfe13%3A0xf512c916279f6232!2sStorage24%20XXL%20Garagen%20-%20Lager%20-%20Hallen!5e0!3m2!1shu!2shu!4v1651685048043!5m2!1shu!2shu'></iframe>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: #fff;
  margin-top: 135px;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
    background-color: #f2f2f2;
  }
  .contact-hero {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 28rem;
    background: url(${contactBg}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      max-height: 75vh;
      background: url(${contactBgMobile}) no-repeat center/cover;
    }
  }
  .content-wrapper {
    width: 1168px;
    height: 80vh;
    max-width: 90%;
    padding-block: 6rem;
    margin: auto;

    @media screen and (max-width: 1024px) {
      padding-top: 0;
      padding-bottom: 0;
      height: auto;
      max-width: 100%;
    }

    h1,
    h2 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.5rem);
      }
    }

    .content-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      h3 {
        font-size: calc(var(--paragraph-font-size) + 0.8rem);
        color: var(--title-text-color);

        @media (max-width: 1024px) {
          font-size: calc(var(--title-font-size) - 0.5rem);
          font-weight: 500;
          text-align: center;
          text-align: left;
        }
      }

      hr {
        width: 12%;
        height: 3px;
        border: none;
        background: var(--cooper-red);

        @media screen and (max-width: 1024px) {
          /* margin: auto; */
        }
      }

      .right-map {
        width: 50%;
        height: 100%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          height: 50vh;
          margin: 0;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 4rem 2rem;
        width: 50%;
        height: 100%;
        background-color: #f2f2f2;

        @media screen and (max-width: 1024px) {
          width: 90%;
          padding: 2rem 0;
          min-height: 50vh;
        }

        img {
          height: 45px;
        }

        p {
          font-size: 1rem;
        }

        .row-1,
        .row-2 {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;

          @media screen and (max-width: 1024px) {
            flex-direction: column;
          }
        }

        .icon-box {
          display: flex;
          width: 50%;
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: 1.5rem;
          min-height: 170px;

          .mail {
            height: 35px;
          }

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }
      }
    }
  }

  .material-symbols-outlined {
    color: var(--secondary-bg);
    font-size: 3rem;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding: 0;
      margin: 6rem auto;

      h1,
      h2 {
        text-align: center;
      }
    }
  }
`

export default ContactUs
