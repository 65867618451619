import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tree from '../assets/images/mountains.webp'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='error-fo-tarolo'>
        <div className='content-wrapper'>
          <div className='text-content'>
            <h1 className='main-text'>{t('404-text')}</h1>
            <Link to='/' className='btn'>
              {t('404-navigate')}
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  padding-top: 125px;
  @media screen and (max-width: 1024px) {
    padding-top: 100px;
  }
  .error-fo-tarolo {
    background-image: url(${tree});
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: center;
    height: calc(100vh - 125px);
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      height: calc(100vh - 100px);
    }

    .content-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .main-text {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      line-height: 1.2;
      width: 80%;
      margin: auto;
      padding-bottom: 5rem;
    }
    .btn {
      border: 3px solid #fff;
      color: var(--secondary-bg);
      background-color: var(--primary-bg);
      font-size: var(--title-font-size);
      width: 50%;
      text-decoration: none;
      text-align: center;
      transition: all 0.3s ease-in-out;
      border-radius: 0;
      padding: 1rem 3rem;

      @media (max-width: 1024px) {
        font-size: var(--button-font-size);
      }
    }
    .btn:hover {
      color: var(--primary-bg);
      background-color: var(--secondary-bg);
    }

    .bg-image {
      padding-right: -15%;
    }
    @media (max-width: 1024px) {
      .content-wrapper {
        flex-direction: column;
      }
    }
  }
`

export default Error
