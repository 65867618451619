import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import aboutImg from '../assets/images/about.webp'
import mountainImage from '../assets/images/mountains.webp'
import whiteLogo from '../assets/logos/logo_white.png'
import testiomonialImage from '../assets/icons/testimonials.svg'
import snowIcon from '../assets/icons/snow.svg'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const isMobile = window.innerWidth < 1024

const AboutUs = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='about-us-upper-container'>
        <div className='about-us-title-container'>
          <h1>{t('rolunk')}</h1>
        </div>

        <div className='about-hero-container'>
          <img src={aboutImg} alt='about' />
          <div className='about-text-container'>
            <div className='about-text-upper-container'>
              <div className='snow-icon'>
                <img src={snowIcon} alt='snow icon' />
              </div>
              <p>{t('about-text-1')}</p>
              <br />
              <p>{t('about-text-2')}</p>
            </div>

            {/* <div>
              <h1>{t('about-title-1')}</h1>
              <h1>{t('about-title-2')}</h1>
              <h1>{t('about-title-3')}</h1>
            </div> */}
          </div>
        </div>
      </div>
      <div className='what-do-you-know-container'>
        <div className='what-do-you-know-title-container'>
          <div className='what-do-you-know-title-text-container'>
            <h2>{t('what-do-you-know')}</h2>
          </div>

          <img src={whiteLogo} alt='white logo' />
        </div>
        <div className='what-do-you-know-text-container'>
          <p className='text-1'>{t('what-do-you-know-text-1')}</p>
          <p className='text-2'>{t('what-do-you-know-text-2')}</p>
        </div>
      </div>
      {/* {!isMobile ? (
        <div className='testimonial-carousel'>
          <div className='quote-column'>
            <img src={testiomonialImage} alt='quote' />
            <q>{t('quote-1')}</q>
            <p>{t('quote-name-1')}</p>
            <p>{t('quote-position-1')}</p>
          </div>
          <div className='quote-column'>
            <img src={testiomonialImage} alt='quote' />
            <q>{t('quote-2')}</q>
            <p>{t('quote-name-2')}</p>
            <p>{t('quote-position-2')}</p>
          </div>
          <div className='quote-column'>
            <img src={testiomonialImage} alt='quote' />
            <q>{t('quote-3')}</q>
            <p>{t('quote-name-3')}</p>
            <p>{t('quote-position-3')}</p>
          </div>
        </div>
      ) : (
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={120}
          slidesPerView={1}
          loop={true}
          pagination
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          speed={1000}
          className='swiper-container'>
          <SwiperSlide className='top-slide'>
            <div className='quote-column'>
              <img src={testiomonialImage} alt='quote' />
              <q>{t('quote-1')}</q>
              <p>{t('quote-name-1')}</p>
              <p>{t('quote-position-1')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='top-slide'>
            <div className='quote-column'>
              <img src={testiomonialImage} alt='quote' />
              <q>{t('quote-2')}</q>
              <p>{t('quote-name-2')}</p>
              <p>{t('quote-position-2')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='top-slide'>
            <div className='quote-column'>
              <img src={testiomonialImage} alt='quote' />
              <q>{t('quote-3')}</q>
              <p>{t('quote-name-3')}</p>
              <p>{t('quote-position-3')}</p>
            </div>
          </SwiperSlide>
        </Swiper>
        
      )} */}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .about-us-upper-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .swiper-container {
    display: flex;
    flex-direction: row;
    width: 1168px;
    width: 100%;
    height: 100%;
    margin-top: 5rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-pagination-bullet {
    width: 8%;
    height: 3px;
    border-radius: 0;
  }

  .about-hero-container {
    display: flex;
    flex-direction: row;
    img {
      width: 519px;
      max-width: 50%;
      height: 678px;
      object-fit: cover;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .about-us-title-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    transform: translateX(43px) translateY(110px);
    h1 {
      font-size: clamp(2rem, 3.2vw, 3.5rem);
      color: var(--primary-bg);
      font-weight: bold;
    }
  }

  .about-text-container {
    position: relative;
    width: 100%;
    background-color: #f2f2f2;
    margin-left: 19px;
    padding-left: 42px;
    padding-right: 42px;
    padding-top: 42px;
    padding-bottom: 42px;
    height: 678px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .about-text-upper-container {
      @media screen and (max-width: 1024px) {
        margin-block: 1.5rem;
      }
    }
    .snow-icon {
      @media screen and (max-width: 1024px) {
        display: none;
      }
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: absolute;
      right: 25px;
      bottom: 5px;
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }
    /* h1 {
      color: var(--secondary-bg);
      font-family: 'Poppins', 'Arial';
      font-size: clamp(2rem, 2.6vw, 3.5rem);
      line-height: clamp(2rem, 3vw, 3.5rem);
    } */
    p {
      /* font-size: clamp(1rem, 1.4vw, 1.3rem); */
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', 'Arial';
      color: #999999;
    }
  }

  .what-do-you-know-container {
    margin-top: 62px;
    background: url(${mountainImage});
    height: 886px;
    width: 100%;
  }

  .what-do-you-know-title-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 73px;

    margin-top: 183px;
    h2 {
      color: var(--primary-bg);
      font-size: clamp(2rem, 2.6vw, 3.5rem);
      font-family: 'Montserrat', sans-serif;
    }
  }

  .what-do-you-know-title-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .what-do-you-know-text-container {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text-1 {
      width: 1100px;
      max-width: 90%;
      margin-left: auto;
      margin-top: 70px;
      margin-bottom: 58px;
      color: var(--primary-bg);
      font-size: clamp(1rem, 1.4vw, 1.3rem);
      font-family: 'Poppins', 'Arial';
      font-weight: 300;

      @media (max-width: 1024px) {
        margin: auto;
        margin-block: 50px;
      }
    }
    .text-2 {
      width: 1100px;
      max-width: 90%;
      margin-left: auto;
      font-weight: 700;
      color: var(--primary-bg);
      font-size: clamp(1rem, 1.4vw, 1.3rem);
      font-family: 'Poppins', 'Arial';

      @media (max-width: 1024px) {
        margin: auto;
        margin-bottom: 50px;
      }
    }
  }

  .testimonial-carousel {
    width: 1168px;
    max-width: 90%;
    margin: auto;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 113px;
    margin-bottom: 113px;
  }

  .quote-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 26px;
    padding-right: 26px;
    img {
      width: 64px;
    }
    q {
      text-align: center;
      margin-bottom: 30px;
      margin-top: 20px;
      color: var(--text-color);
      font-size: calc(var(--paragraph-font-size) + 0.2rem);
      font-family: 'Poppins', 'Arial';
      font-weight: 300;
    }
    p {
      font-size: var(--paragraph-font-size);
      font-family: 'Poppins', 'Arial';
      font-weight: 300;
    }
  }

  @media screen and (max-width: 1024px) {
    .about-us-upper-container {
      padding: 0;
    }
    .about-hero-container {
      flex-direction: column;
      width: 100%;
      min-height: 100vh;
      margin-top: -50px;
      img {
        width: 100%;
        max-width: 100%;
        height: 60vh;
      }
    }

    .about-us-title-container {
      transform: translateX(43px) translateY(75px);
    }
    .about-text-container {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
      padding-bottom: 5%;
      margin: 0;
      height: fit-content;
      .about-text-upper-container {
        margin-bottom: 30px;
      }
    }

    .what-do-you-know-container {
      height: 100%;
      margin-top: 0;
    }

    .what-do-you-know-title-container {
      flex-direction: column;
      height: fit-content;
      align-items: center;
      margin-top: 5rem;
      padding-left: 5%;
      padding-right: 5%;
      img {
        width: 40%;
      }
    }
    .what-do-you-know-title-text-container {
      order: 2;
      margin-top: 2rem;
    }

    .text-2 {
      margin-bottom: 3rem;
    }
    .testimonial-carousel {
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
      margin-top: 5rem;
      margin-bottom: 0;
    }

    .quote-column {
      margin-bottom: 5rem;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 1024px) {
    .about-us-title-container {
      transform: translateX(120px) translateY(75px);
    }
  }
`

export default AboutUs
