import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import pdfImage from '../assets/icons/pdf-icon.svg'
import download from '../assets/images/mountains-bg.png'
import Modal from '../components/Modal'
import products from '../helper/List'
import { useParams, NavLink } from 'react-router-dom'

const Product = () => {
  const { t } = useTranslation()
  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const productID = useParams().product

  const elem = products.find((product) => {
    return product.id.includes(productID)
  })

  let handleClick
  elem.gallery.map((img, i) => {
    handleClick = () => {
      setCurrentIndex(i)
      setClickedImg(img)
    }
    return img
  })

  const handelRotationRight = () => {
    const totalLength = elem.gallery.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newUrl = elem.gallery[0]
      setClickedImg(newUrl)
      return newUrl
    }
    const newIndex = currentIndex + 1
    const img = elem.gallery
    const newUrl = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newUrl[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = elem.gallery.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newUrl = elem.gallery[totalLength - 1]
      setClickedImg(newUrl)
      return newUrl
    }
    const newIndex = currentIndex - 1
    const img = elem.gallery
    const newUrl = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newUrl[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  return (
    <Wrapper>
      <div className='content-wrapper'>
        <div className='product-container'>
          <div className='product-description'>
            <h1>{elem.title}</h1>
            <hr />
            {elem.introduction && (
              <span className='product-introduction'>{elem.introduction}</span>
            )}
            <ul>
              {elem.description.map((description, id) => {
                return <li key={id}>{description}</li>
              })}
            </ul>
          </div>
          <div>
            <div className='product-gallery'>
              <div className='product-gallery'>
                <img
                  src={elem.image}
                  alt={elem.title}
                  onClick={() => handleClick(elem.image, elem.id)}
                />
                <div className='small-image'>
                  {elem.gallery.map((item, i) => {
                    return (
                      <img
                        src={item}
                        alt={elem.title}
                        key={i}
                        onClick={() => handleClick(elem.image, elem.id)}
                      />
                    )
                  })}
                </div>

                {clickedImg && (
                  <Modal
                    clickedImg={clickedImg}
                    handelRotationRight={handelRotationRight}
                    setClickedImg={setClickedImg}
                    handelRotationLeft={handelRotationLeft}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <>
          {elem.cooling && elem.heating && (
            <div className='product-long-description'>
              <div className='product-attributes'>
                <div className='product-attributes-description'>
                  <p>Cooling </p>
                  <strong>{elem.cooling} kW</strong>
                </div>
                <div className='product-attributes-description'>
                  <p>Heating</p>
                  <strong>{elem.heating} kW</strong>
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {elem.data && (
            <div className='datasheet-wrapper'>
              <h2>Parameters:</h2>
              <hr />
              <table className='datasheet-container'>
                {elem.data.map((parameters) => (
                  <div className='datasheet-table'>
                    <thead className=''>
                      <tr className='head'>
                        <th scope='col'>{parameters.name} </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{parameters.value}</td>
                      </tr>
                    </tbody>
                  </div>
                ))}
              </table>
            </div>
          )}
        </>
      </div>
      <div className='btn-back'>
        <NavLink to={`/categories/${useParams().id}`}>
          <button className='basic-btn'>&#8592; {t('back')}</button>
        </NavLink>
      </div>
      <a
        className='download-bg'
        href={elem.downloads}
        target='_blank'
        rel='noreferrer'>
        <div className='download-container'>
          <h2>Download specification</h2>
          <img src={pdfImage} alt='download pdf' />
        </div>
      </a>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 150px;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }

  .content-wrapper {
    width: 1168px;
    max-width: 90%;
    padding-block: 5rem;
    margin: auto;

    @media (max-width: 1024px) {
      padding: 3rem 0;
    }
  }

  .small-image {
    display: flex;
    flex-direction: column;
  }

  .product-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  hr {
    width: 12%;
    height: 3px;
    border: none;
    background: var(--cooper-red);
    margin-bottom: 2.5rem;
  }

  .product-description {
    @media screen and (max-width: 1024px) {
      order: 2;
    }

    h1 {
      font-size: 1.5rem;
    }

    ul {
      margin-left: 1rem;
      list-style-type: none;

      li:before {
        content: '• ';
        color: var(--secondary-bg);
        font-weight: bold;
        display: inline-block;
        font-size: 1.3rem;
        width: 1rem;
        margin-left: -1rem;
      }

      li {
        font-size: 1.125rem;
      }
    }
  }

  .product-gallery {
    display: inline-flex;
    align-items: center;
  }

  .product-gallery img {
    width: 450px;
    object-fit: cover;
    cursor: pointer;

    @media screen and (max-width: 761px) {
      width: 280px;
    }
  }

  .product-attributes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .product-introduction {
    display: block;
    width: 95%;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 2rem;
    background-color: #f2f2f2;
    padding: 0.5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .product-long-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 2rem;
    }

    div {
      display: flex;
      flex-direction: column;

      p {
        width: 100%;
        font-size: 1.125rem;
      }

      .product-attributes-description {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 1024px) {
          margin-top: 2rem;
        }
      }
    }

    .product-attributes-description:first-child p {
      width: 20%;
      background-color: #e5e5e5;
      padding: 1.5rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    .product-attributes-description:nth-child(2) p {
      width: 20%;
      background-color: #f2f2f2;
      padding: 1.5rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    strong {
      padding: 1.5rem;
      width: 40%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    p {
      width: 60%;
      font-size: 1.125rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    ul {
      background-color: gray;
      display: flex;
      flex-direction: row;

      list-style-type: none;
    }
  }

  .download-bg {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 10rem;
    background: url(${download}) no-repeat center/cover;
    margin: 3rem 0 0;

    .download-container {
      width: 100%;
      padding: 0 20%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 1024px) {
        padding: 0 20% 0 5%;
      }
    }

    h2 {
      color: white;
      margin-right: 5rem;
    }
  }

  .btn-back {
    display: block;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-top: 2rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .basic-btn {
    display: inline-block;
    color: var(--title-text-color);
    background: transparent;
    text-transform: uppercase;
    padding: 0.5rem 4rem;
    border: 2px solid var(--title-text-color);
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    :hover {
      background-color: var(--secondary-bg);
      color: var(--primary-bg);
      border-color: var(--secondary-bg);
    }

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }

  .datasheet-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .datasheet-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }

  thead,
  tbody {
    height: 100px;

    @media screen and (max-width: 1024px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: auto;
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  .head {
    background-color: #f2f2f2;
  }

  table tr {
    border: 1px solid #ddd;
    padding: 0.35em;
  }

  table td {
    font-weight: 300;
    font-size: 0.9rem;
  }

  table th,
  table td {
    padding: 0.625em;
    text-align: center;
  }

  table th {
    font-size: 0.9rem;
    font-weight: 600;
  }

  @media screen and (max-width: 1024px) {
    .datasheet-table {
      width: 100%;
    }
  }

  .small-image img {
    width: 50px;
  }
`

export default Product
