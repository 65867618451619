import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

// images
import cooperHead from '../assets/images/candh-hero.webp'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='contact-hero'>
        <div className='content-wrapper inner'>
          <h1>{t('carousel-h2')}</h1>
          <h2>IMPRESSUM</h2>
        </div>
      </div>
      <div className='content-wrapper'>
        <div>
          <h2>Dienstleister</h2>
          <h3>Netlify Inc.</h3>
          <picture>
            {/* <img className='logo' src={netlifyLogo} alt='Netlify logo' /> */}
          </picture>
          <div>
            <h3>Center:</h3>
            <p>
              44 Montgomery Street, Suite 300, San Francisco, California 94104.
            </p>
            <h3>Postanschrift:</h3>
            <p>
              44 Montgomery Street, Suite 300, San Francisco, California 94104.
            </p>
            <h3>Email:</h3>
            <p>support@netlify.com</p>
          </div>
        </div>

        <div>
          <h2>Website-Betreiber</h2>
          <h3>C&H Klima s.r.o </h3>
          <picture>{/* <img src={logo} alt='Netlify logo' /> */}</picture>
          <div>
            <h3>Center / Postanschrift:</h3>
            <p>
              Lager- und Garagenpark Laaber Strase 53. 2384 Breitenfurt bei Wien
            </p>
            <h3>Steuernummer:</h3>
            <p>AT 093950848</p>
            <h3>Telefonnummer:</h3>
            <p>+43 664 2174381</p>
            <h3>E-Mail Addresse:</h3>
            <p>office@cooperklima.at</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: #fff;
  margin-top: 135px;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
  .contact-hero {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 530px;
    background: url(${cooperHead}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      height: 56.2vh;
      background: url(${cooperHead}) no-repeat left/cover;
    }
  }
  .content-wrapper {
    width: 1168px;
    height: 100%;
    max-width: 90%;
    padding-block: 6rem;
    margin: auto;
    text-align: center;

    @media screen and (max-width: 1024px) {
      padding-top: 3rem;
      padding-bottom: 0;
      height: auto;
      max-width: 90%;
    }

    h1,
    h2 {
      font-size: var(--title-font-size);
      color: var(--title-text-color);
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2rem;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.8rem);
      }
    }

    h3 {
      font-size: calc(var(--title-font-size) - 0.3rem);
      color: var(--primary-bg);
      text-transform: uppercase;
      font-weight: 400;
      color: var(--title-text-color);

      @media (max-width: 1024px) {
        font-size: calc(var(--title-font-size) - 0.3rem);
      }
    }

    p {
      font-size: 20px;
      font-weight: 700;
    }

    p,
    hr {
      margin-bottom: 2rem;
    }

    hr {
      width: 12%;
      height: 3px;
      border: none;
      background: var(--cooper-red);

      @media screen and (max-width: 1024px) {
        /* margin: auto; */
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding: 0;
    }

    h1,
    h2 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-align: left;
      text-decoration: none;
      margin-bottom: 0;
      text-shadow: 0 4px 5px #000;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.5rem);
        text-align: center;
      }
    }
  }
`

export default ContactUs
