import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

// images
import cooperHead from '../assets/images/candh-hero.jpeg'

const contents = [1, 2, 3, 4, 5, 6, 7]

const Cookies = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='contact-hero'>
        <div className='content-wrapper inner'>
          <h2>{t('carousel-h2')}</h2>
          <h2>{t('gdpr')}</h2>
        </div>
      </div>
      <div className='content-wrapper'>
        {contents.map((content, i) => (
          <div key={i}>
            <h2>{t(`gdpr-subtitle-${i + 1}`)}</h2>
            <span>{t(`gdpr-text-${i + 1}`)}</span>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color: #fff;
  margin-top: 135px;
  width: 100vw;
  height: 100%;

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
  .contact-hero {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    height: 530px;
    background: url(${cooperHead}) no-repeat center/cover;

    @media screen and (max-width: 1024px) {
      height: 56.2vh;
      background: url(${cooperHead}) no-repeat left/cover;
    }
  }
  .content-wrapper {
    width: 1168px;
    height: 100%;
    max-width: 90%;
    padding-block: 6rem;
    margin: auto;

    @media screen and (max-width: 1024px) {
      padding-block: 3rem;
      height: auto;
      max-width: 90%;
    }

    .cookie-tipus {
      font-weight: 700;
    }

    span {
      display: block;
      margin-top: 0.5rem;
    }

    h1,
    h2 {
      font-size: var(--title-font-size);
      color: var(--title-text-color);
      text-transform: uppercase;
      text-align: left;
      margin-top: 2rem;
      margin-bottom: 0.5rem;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.8rem);
      }
    }

    h3 {
      font-size: calc(var(--title-font-size) - 0.3rem);
      color: var(--primary-bg);
      text-transform: uppercase;
      font-weight: 400;
      color: var(--title-text-color);

      @media (max-width: 1024px) {
        font-size: calc(var(--title-font-size) - 0.3rem);
      }
    }

    p,
    hr {
      margin-bottom: 2rem;
    }

    hr {
      width: 12%;
      height: 3px;
      border: none;
      background: var(--cooper-red);

      @media screen and (max-width: 1024px) {
        /* margin: auto; */
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding: 0;
    }

    h1,
    h2 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-align: left;
      text-decoration: none;
      margin: 0;
      padding: 0;
      text-shadow: 0 4px 5px #000;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.5rem);
        text-align: center;
      }
    }
  }
`

export default Cookies
